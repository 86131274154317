import { useFastCheckoutContext } from "@/components/Checkout/CheckoutMachine/FastCheckoutMachineProvider";
import {
  fastCheckoutDisplayData,
  FastCheckoutState,
} from "@/components/Checkout/CheckoutMachine/types/FastCheckoutMachine";

import { useSelector } from "@xstate/react";
import { styled, Box } from "@mui/material";
import React from "react";
import ProgressTracker from "../components/ProgressAnimation";
import { MOBILE_SCREEN_SIZE, TABLET_SCREEN_SIZE } from "@brandclub/common-ui";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",

  ".header_label": {
    color: theme.palette.primary.main,
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "10px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: "22px",
      marginBottom: "6px",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: "22px",
      marginBottom: "6px",
    },
  },
  ".header_sub_label": {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: "16px",
      marginBottom: "24px",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {},
  },
  ".order_notification": {
    marginTop: "16px",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 500,
  },
}));

export const getHeaderLabel = (checkoutState?: FastCheckoutState) => {
  return fastCheckoutDisplayData[checkoutState ?? ""] ?? "";
};

const CheckoutHead = () => {
  const { actorRef } = useFastCheckoutContext();

  const order = useSelector(
    actorRef,
    (snapshot) => snapshot.context?.retailerCheckoutOrderStatus
  );

  const checkoutState = useSelector(
    actorRef,
    (snapshot) => snapshot.context?.checkoutState
  );

  if (!order) {
    return null;
  }

  return (
    <Container>
      <div className="header_label">{getHeaderLabel(checkoutState)}</div>
      <div className="header_sub_label">
        Order #{order?.userRetailerCheckoutOrder?.orderId}
      </div>
      <ProgressTracker activeStep={3} />
      <div className="order_notification">Completing your order</div>
    </Container>
  );
};

export default CheckoutHead;
