import { Skeleton, styled, Box } from "@mui/material";

import {
  desktopHeaderHeight,
  mobileHeaderHeight,
} from "../StoreSinglePageOutlet";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { StoreSpin } from "@/components/Loading";

const HeaderContainer = styled("div")(() => ({
  top: 0,
  backgroundColor: "#ffffff",
  height: desktopHeaderHeight,
  width: "100%",
  boxShadow: "0 5px 10px 0 rgba(181, 176, 176, 0.16)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 80,
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: mobileHeaderHeight,
    marginBottom: 30,
  },
}));

const SinglePageSkeletonFullLoading = ({
  includeHeader,
}: {
  includeHeader?: boolean;
}) => {
  return (
    <>
      {includeHeader && (
        <HeaderContainer data-testid="single-page-skeleton-full-loading-header">
          <Skeleton
            variant="rectangular"
            style={{ borderRadius: 6 }}
            sx={{
              bgcolor: "#e6ebf0",
              display: "block",
              boxSizing: "border-box",
              width: 60,
              height: 60,
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                width: 40,
                height: 40,
              },
            }}
          />
        </HeaderContainer>
      )}

      <Box
        role="status"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "20px",
          paddingBottom: "50px",
          justifyContent: "center",
          boxSizing: "border-box",
          flexDirection: "column",
          gap: "35px",
          minHeight: "71vh",
        }}
      >
        {/* Hide spinner for screen readers as the loading state is announced by the parent div and text. */}
        <StoreSpin aria-hidden="true" thickness={6} size={80} />
      </Box>
    </>
  );
};

export default SinglePageSkeletonFullLoading;
