import { Box, styled } from "@mui/material";
import StoreRadio from "../../../StoreComponents/StoreRadio";
import { ShippingGroup } from "../../types";
import { SummaryProduct } from "../SummaryProductV2";
import { StoreAnimatedHeightDiv } from "../../../StoreComponents/StoreAnimatedHeightDiv";
import { AnimatePresence, motion } from "framer-motion";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

interface ShippingGroupPreviewProps {
  shippingGroup: ShippingGroup;
  deliveryOpen: boolean[];
  index: number;
  openItem: (index: number) => void;
  handleUpdateShippingOption: (
    deliveryOption?:
      | {
          name: string;
          cssSelector: string;
        }
      | undefined,
    deliveryTimeWindow?:
      | {
          name: string;
          cssSelector: string;
        }
      | undefined
  ) => Promise<void>;
}

const Container = styled(Box)({
  padding: "0px 0",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,

  ".selected_title": {
    fontSize: 16,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".product_list": {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    maxWidth: 420,
  },
  ".shipping_options": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: 600,
    ".header": {
      fontSize: 16,
      fontWeight: 600,
    },
    ".single_option": {
      ".option_title": {
        fontSize: 14,
      },
      ".window_list": {
        paddingLeft: 28,
        paddingTop: 12,
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        flexWrap: "wrap",
      },
    },
  },
});

const ShippingOption = ({
  option,
  updateOrderShippingOption,
}: {
  option: ShippingGroup["deliveryOptions"][0];
  updateOrderShippingOption: (
    deliveryOption?:
      | {
          name: string;
          cssSelector: string;
        }
      | undefined,
    deliveryTimeWindow?:
      | {
          name: string;
          cssSelector: string;
        }
      | undefined
  ) => Promise<void>;
}) => {
  if (!option.deliveryTimeWindows || option.deliveryTimeWindows.length === 0) {
    return (
      <Box className="single_option">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <StoreRadio
              key={option.name + option.cssSelector}
              checked={!!option.isSelected}
              disableRipple
              onClick={() => {
                updateOrderShippingOption({
                  name: option.name,
                  cssSelector: option.cssSelector,
                });
              }}
            />
            <Box
              marginLeft={1}
              className="option_title"
              sx={{
                fontWight: option.isSelected ? 600 : 500,
              }}
            >
              {option.name} - {option.description}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        {option.deliveryTimeWindows
          .filter((time) => {
            return time?.name || time?.description;
          })
          .map((e) => {
            return (
              <Box className="single_option" key={e.name + e.cssSelector}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <StoreRadio
                      key={e.name + e.cssSelector}
                      checked={!!e.isSelected}
                      disableRipple
                      onClick={() => {
                        updateOrderShippingOption(undefined, {
                          name: e.name,
                          cssSelector: e.cssSelector,
                        });
                      }}
                    />
                    <Box marginLeft={1} className="option_title">
                      {e.name} - {option.description}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </>
    );
  }
};

export const ShippingGroupPreview = ({
  shippingGroup,
  index,
  handleUpdateShippingOption,
  deliveryOpen,
  openItem,
}: ShippingGroupPreviewProps) => {
  const selectedOption = shippingGroup.deliveryOptions.find(
    (e) => e.isSelected
  );
  const selectedTimeWindow = selectedOption?.deliveryTimeWindows?.find(
    (e) => e.isSelected
  );

  return (
    <>
      <Container className="shipping_group">
        <div
          className="selected_title"
          onClick={() => {
            openItem(index);
          }}
        >
          {selectedOption?.deliveryTimeWindows &&
          selectedOption.deliveryTimeWindows.length >= 1
            ? `${selectedTimeWindow?.name} ${
                selectedOption?.description ? "-" : ""
              } ${selectedOption?.description ?? ""}`
            : `${selectedOption?.name} ${
                selectedOption?.description ? "-" : ""
              } ${selectedOption?.description ?? ""}`}
        </div>
        <div className="product_list">
          {shippingGroup.products.map((e, index) => {
            return <SummaryProduct key={index} product={e as any} />;
          })}
        </div>

        {deliveryOpen[index] ? (
          <StoreAnimatedHeightDiv>
            <AnimatePresence mode="wait">
              <motion.div
                layout="position"
                key={deliveryOpen[index] ? "view" : "edit"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
              >
                <Box className="shipping_options">
                  {shippingGroup.deliveryOptions.map((e) => {
                    if (!e.name && !e.description) {
                      return null;
                    }

                    return (
                      <ShippingOption
                        key={e.name + e.description}
                        option={e}
                        updateOrderShippingOption={handleUpdateShippingOption}
                      />
                    );
                  })}
                </Box>
              </motion.div>
            </AnimatePresence>
          </StoreAnimatedHeightDiv>
        ) : null}
      </Container>
    </>
  );
};
