import { useContext, useEffect, useState } from "react";
import StoreInput from "../../StoreComponents/StoreInput";
import AuthSolidButton from "./components/AuthSolidButton";
import { ReactComponent as AddUser } from "../../../assets/Add_User.svg";
import {
  formatPhone,
  getPhoneNumberMasking,
  validatePhone,
} from "../../../utils/StringUtils";
import { ButtonLoadingEllipsis } from "../../StoreComponents/StoreButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { UserLoginContext } from "./UserLoginProvider";
import { clearBrandEntity } from "../../../redux/reducers/brandEntity";
import { TermsAndConditions } from "./TermsAndConditions";
import { ButtonBase, useTheme } from "@mui/material";
import { BRANDCLUB_BRAND_ID } from "@brandclub/common-ui";

import OTPWrapper from "./OTPWrapper";
import { ReactComponent as RightArrow } from "../../../assets/right-arrow.svg";

import { NumberFormatCustom } from "@/components/StoreComponents/NumberFormatCustom";

const ROW_SPACING = 20;

const SignIn = ({
  isUpdatePhoneFlow = false,
}: {
  isUpdatePhoneFlow?: boolean;
}) => {
  const { send, signIn, signInError, setSignInError, submittingSignIn } =
    useContext(UserLoginContext);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appConfig?.domainConfig.brandId === BRANDCLUB_BRAND_ID) {
      dispatch(clearBrandEntity());
    }
  }, [dispatch, appConfig?.domainConfig.brandId]);

  const [phone, setPhone] = useState("");

  const theme = useTheme();

  const submit = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const phoneNumber = formatPhone(phone, "e164");

    signIn(
      phoneNumber,
      isUpdatePhoneFlow,
      (isNewUser: boolean, session: any) => {
        return send({
          type: "MOVE_TO_VERIFY_OTP",
          cognitoSession: session,
          otpPhoneNumber: phoneNumber,
          isNewUser,
        });
      }
    );
  };

  const clearErrors = () => {
    setSignInError("");
  };

  const isNumberValid = validatePhone(phone);

  const phoneMasking = getPhoneNumberMasking(phone || "");

  return (
    <OTPWrapper>
      <div className="auth_container">
        <AddUser
          style={{
            width: 75,
            height: 75,
            fill: theme.palette.primary.main,
          }}
        />
        <div className="page_title">Enter your phone number</div>
        <div className="page_subtitle">
          We will text you a verification code.
        </div>
        <StoreInput
          errorMsg={signInError || ""}
          value={phone}
          autoFocus
          onChange={(e) => {
            const value = e.target.value;
            setPhone(value?.trim());
            clearErrors();
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              format: phoneMasking,
            },
          }}
          placeholder="Phone number"
          inputStyle={{ width: "100%" }}
          style={{ marginTop: ROW_SPACING }}
        ></StoreInput>
        <AuthSolidButton
          onClick={submit}
          disabled={submittingSignIn || !isNumberValid}
          style={{ marginTop: ROW_SPACING }}
        >
          {submittingSignIn ? (
            <>
              Submitting
              <ButtonLoadingEllipsis
                blue
                baseFontSize={16}
                customColor="#dcdddc"
              />
            </>
          ) : (
            "Continue"
          )}
        </AuthSolidButton>
        {isUpdatePhoneFlow ? null : (
          <ButtonBase
            onClick={() => send({ type: "CHANGE_PHONE_NUMBER" })}
            sx={{
              marginTop: "30.5px",
              fontWeight: 500,
              display: "flex",
              fontSize: "14px",
              lineHeight: "22px",
              flexDirection: "row",
              alignItems: "center",
              gap: "6.5px",
            }}
          >
            Update your phone number <RightArrow />
          </ButtonBase>
        )}
        <div className="termsAgreement" style={{ maxWidth: 300 }}>
          <TermsAndConditions />
        </div>
      </div>
    </OTPWrapper>
  );
};

export default SignIn;
