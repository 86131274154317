import { useAppSelector } from "../../../../redux/hooks";

import { styled } from "@mui/material";
import FlowContainer from "../../../pages/AppPages/Pages/components/FlowContainer";
import { StoreBrandingType } from "../../../../types/misc";
import { useCheckoutContext } from "../../CheckoutMachine/CheckoutProvider";
import StoreMessageCard from "@/components/StoreComponents/StoreCard.tsx/StoreMessageCard";
import { TABLET_SCREEN_SIZE } from "@brandclub/common-ui";
import { isAllOutOfStock } from "../../CheckoutMachine/utils";
import { useSelector } from "@xstate/react";

const StyledFlowContainer = styled(FlowContainer, {
  label: "all-out-of-stock-screen__flow-container",
})(() => ({
  justifyContent: "center",
  paddingTop: "30px",
  paddingBottom: "30px",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    justifyContent: "center",
  },
}));

const StyledStoreMessageCard = styled(StoreMessageCard, {
  label: "all-out-of-stock-screen__store-message-card",
})({
  width: "100%",
  maxWidth: 933,
  ".store-message-card__title": {
    letterSpacing: "0.02em",
  },
});

export const useIsAllOutOfStock = () => {
  const { actorRef } = useCheckoutContext();

  const order = useSelector(
    actorRef,
    (snapshot) => snapshot.context?.retailerCheckoutOrderStatus
  );
  const batch = useSelector(
    actorRef,
    (snapshot) => snapshot.context?.checkoutBatch
  );
  const state = useSelector(actorRef, (snapshot) => snapshot.value);

  if (!order || !batch) {
    return { allOutOfStock: false, isSingleRetailerCheckout: false };
  }
  // avoid premature rendering of the all out of stock screen
  const allOutOfStock = state === "checkoutError" && isAllOutOfStock(order);
  const isSingleRetailerCheckout = batch.retailerIds?.length === 1;

  return { allOutOfStock, isSingleRetailerCheckout };
};

const AllOutOfStockScreen = () => {
  const storeBrandingType = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.storeBrandingType
  );
  const customShopDomainName = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig?.customShopDomainName
  );

  const linkTo =
    storeBrandingType === StoreBrandingType.CustomDTCStore &&
    customShopDomainName
      ? `https://${customShopDomainName}/`
      : `/shop`;

  return (
    <StyledFlowContainer>
      <StyledStoreMessageCard
        title="It looks like your items are out of stock."
        content="The retailer no longer has inventory available for your order."
        cta="Shop popular products"
        linkTo={linkTo}
      />
    </StyledFlowContainer>
  );
};

export default AllOutOfStockScreen;
