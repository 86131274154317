import { ChevronDownIcon, ChevronUpIcon } from "@brandclub/common-ui";
import { Collapse, styled } from "@mui/material";
import React from "react";

interface CollapsibleSectionProps {
  isOpen: boolean;
  onToggle?: () => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  hideControl?: boolean;
  error?: boolean;
}

const StyledCollapsibleSection = styled("div")<{
  error?: boolean;
  disabled?: boolean;
}>(({ disabled }) => ({
  overflow: "hidden",
  opacity: disabled ? 0.5 : 1,
}));

const Header = styled("button")<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px",
    border: "none",
    cursor: disabled ? "default" : "pointer",
    background: "transparent",
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "left",
  })
);

const Content = styled("div")(() => ({
  padding: "16px",
}));

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  isOpen,
  onToggle,
  title,
  children,
  disabled,
  hideControl = false,
  error,
}) => {
  return (
    <StyledCollapsibleSection disabled={disabled}>
      <Header disabled={disabled} onClick={onToggle}>
        {title}
        {!hideControl && (
          <div style={{ transform: "translateX(16px)" }}>
            {isOpen ? (
              <ChevronUpIcon
                style={{
                  width: "48px",
                  height: "48px",
                }}
              />
            ) : (
              <ChevronDownIcon
                style={{
                  width: "48px",
                  height: "48px",
                  opacity: disabled ? 0.5 : 1,
                }}
              />
            )}
          </div>
        )}
      </Header>
      <Collapse in={isOpen}>
        <Content>{children}</Content>
      </Collapse>
    </StyledCollapsibleSection>
  );
};

export default CollapsibleSection;
