import { ChevronDownIcon } from "@brandclub/common-ui";
import { Box } from "@mui/material";
import { useSelector } from "@xstate/react";
import { AnimatePresence, motion } from "framer-motion";
import _startCase from "lodash/startCase";

import CardBrandIcon from "../../../../pages/AppPages/Dashboard/Wallet/CardBrandIcon";
import { StoreAnimatedHeightDiv } from "../../../../StoreComponents/StoreAnimatedHeightDiv";
import { useFastCheckoutContext } from "../../../CheckoutMachine";

import { UserRetailerCheckoutOrder } from "../../../types";
import InfoBox from "../InfoBox";

interface PaymentMethodProps {
  paymentMethod: UserRetailerCheckoutOrder["paymentMethod"];
  onAddPaymentMethod: () => void;
}
const PaymentMethod = (props: PaymentMethodProps) => {
  const { paymentMethod } = props;
  if (!paymentMethod) {
    return (
      <InfoBox message="Please add a payment method to your retailer account." />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        <CardBrandIcon
          className="icon"
          alt="icon"
          cardBrand={paymentMethod?.cardIssuer?.toLocaleLowerCase()}
        />
        {_startCase(paymentMethod?.cardIssuer || "")} ending in{" "}
        {paymentMethod?.cardNumberEnding}
      </div>
    </div>
  );
};
const PaymentSection = () => {
  const { actorRef, send } = useFastCheckoutContext();

  const open = useSelector(
    actorRef,
    (state) => state.context.sectionState.payment.open
  );
  const chevronShow = useSelector(
    actorRef,
    (state) => state.context.sectionState.payment.chevronShow
  );

  const toggleOpen = () => {
    if (open) {
      send({
        type: "ON_SESSION_CLOSE",
        data: { sessionToClose: "payment" },
      });
    } else {
      send({
        type: "ON_SESSION_OPEN",
        data: { sessionToOpen: "payment" },
      });
    }
  };

  const userRetailerCheckoutOrder = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
  );

  return (
    <Box className="section">
      <div className="section_head" style={{ cursor: "pointer" }}>
        <div className="title">{"Payment Method"}</div>
        {chevronShow ? (
          <ChevronDownIcon
            style={{
              transition: "transform 0.3s",
              width: "33px",
              height: "33px",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={toggleOpen}
          ></ChevronDownIcon>
        ) : null}
      </div>
      <StoreAnimatedHeightDiv>
        <PaymentMethod
          paymentMethod={userRetailerCheckoutOrder?.paymentMethod}
          onAddPaymentMethod={() => {
            send({
              type: "ON_SESSION_OPEN",
              data: { sessionToOpen: "payment" },
            });
          }}
        />
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={open ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {open ? <div>more Payment Method</div> : null}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default PaymentSection;
