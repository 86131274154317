import { Box } from "@mui/material";

import { ShippingAddress } from "../../types";
import { useOrder } from "../../OrderProvider";
import CheckoutEditShippingForm from "./CheckoutEditShippingForm";
import { getSortedAddresses } from "../../utils";

import { ChevronDownIcon } from "@brandclub/common-ui";
import { StoreAnimatedHeightDiv } from "../../../StoreComponents/StoreAnimatedHeightDiv";
import { AnimatePresence, motion } from "framer-motion";
import { buildAddressString } from "../..";
import InfoBox from "./InfoBox";
import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";
import { UserInputUpdateType } from "../../CheckoutMachine/types/CheckoutMachine";
import { useSelector } from "@xstate/react";
import { useCheckoutContext } from "../../CheckoutMachine/CheckoutProvider";

interface SelectedAddressProps {
  shippingAddress?: Partial<ShippingAddress>;
  onAddShippingAddress: () => void;
}
const SelectedAddress = (props: SelectedAddressProps) => {
  const { shippingAddress } = props;
  if (!shippingAddress) {
    return (
      <InfoBox
        message="Please add a shipping address to complete this purchase."
        cta="Add new shipping address"
        onClick={props.onAddShippingAddress}
      />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        {shippingAddress?.firstName} {shippingAddress?.lastName}
      </div>

      <div className="info_row address">
        {buildAddressString(shippingAddress)}
      </div>
    </div>
  );
};

const ShippingSection = () => {
  const { openShipping, setOpenShipping } = useOrder();
  const { actorRef, send: checkoutSend } = useCheckoutContext();
  const shippingAddress = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.shippingAddress
  );
  const availableAddresses = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.availableAddresses
  );
  const errorMessage = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus
        ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type
  );
  const userInputError = useSelector(actorRef, (snapshot) =>
    snapshot.context.userInputUpdateType &&
    [
      UserInputUpdateType.ADD_SHIPPING_ADDRESS,
      UserInputUpdateType.UPDATE_SHIPPING_ADDRESS,
    ].includes(snapshot.context.userInputUpdateType)
      ? snapshot.context.userInputError
      : undefined
  );

  const [error, setError] = useState("");

  useEffect(() => {
    if (
      errorMessage === "addShippingAddress" ||
      errorMessage === "updateShippingAddress" ||
      !!userInputError
    ) {
      setError(ERROR_MESSAGES.default[0]);
      setOpenShipping(true);
    }
  }, [userInputError, errorMessage, setOpenShipping]);

  const sortedShippingAddresses = getSortedAddresses(availableAddresses);

  const handleUpdateShippingAddress = async (
    method: "add" | "update",
    address: Partial<ShippingAddress>
  ) => {
    try {
      setError("");
      if (method === "add") {
        checkoutSend({
          type: "CHECKOUT_USER_INPUT_EVENT",
          inputEvent: UserInputUpdateType.ADD_SHIPPING_ADDRESS,
          data: {
            address: address as ShippingAddress,
          },
        });
      } else {
        checkoutSend({
          type: "CHECKOUT_USER_INPUT_EVENT",
          inputEvent: UserInputUpdateType.UPDATE_SHIPPING_ADDRESS,
          data: {
            address: address as ShippingAddress,
          },
        });
      }
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : ERROR_MESSAGES.default[0];
      setError(errorMessage);
    }
  };

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setError("");
          setOpenShipping(!openShipping);
        }}
      >
        <div className="title">{"Shipping Address"}</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: openShipping ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>

      <StoreAnimatedHeightDiv>
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={openShipping ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {openShipping ? (
              <CheckoutEditShippingForm
                availableAddresses={sortedShippingAddresses}
                handleUpdateShippingAddress={handleUpdateShippingAddress}
                existingAddress={shippingAddress}
                error={error}
                cancelEditing={() => {
                  setOpenShipping(false);
                }}
              />
            ) : (
              <SelectedAddress
                shippingAddress={shippingAddress}
                onAddShippingAddress={() => setOpenShipping(true)}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default ShippingSection;
