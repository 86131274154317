import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import { Retailer } from "@/redux/types";
import sortBy from "lodash/sortBy";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserRetailerShoppingCart } from "../../types";

interface IFastCheckoutCartContextParams {
  setShipping: (shippingInfo: any) => void;
  shipping: any;
  amountToCharge: number | null;
  currentCart?: UserRetailerShoppingCart;
  currentRetailer?: Retailer;
}

// Create a context with a default value.
const FastCheckoutCartContext = createContext<IFastCheckoutCartContextParams>(
  {} as IFastCheckoutCartContextParams
);

// Create a Provider component.
export const FastCheckoutCartProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const userProfile = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getActiveCarts());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile?.userId) {
      // refresh the cart
      dispatch(getActiveCarts());
    }
  }, [userProfile?.userId, dispatch]);

  const allCarts = useAppSelector((state) =>
    sortBy(
      state.checkout.allCarts.filter((cart) =>
        ["queued", "pending", "active", "timed_out", undefined].includes(
          cart.sessionStatus
        )
      ),
      (cart) => cart.items.length
    ).reverse()
  );

  const allRetailers = useAppSelector((state) => state.retailers);
  // State
  const [shipping, setShipping] = useState<any>();
  const currentRetailerId = allCarts[0]?.retailerId; // Placeholder

  const currentCart = allCarts.find(
    (cart) => cart.retailerId === currentRetailerId
  );
  const currentRetailer = allRetailers.find(
    (retailer) => retailer.retailerId === currentRetailerId
  );
  const amountToCharge = currentCart?.items.reduce(
    (acc, item) => acc + item.retailPrice * item.quantity,
    0
  );

  const contextValue = useMemo(
    () => ({
      amountToCharge: amountToCharge ?? null,
      shipping,
      setShipping,
      currentCart,
      currentRetailer,
    }),
    [amountToCharge, shipping, currentCart, currentRetailer]
  );

  return (
    <FastCheckoutCartContext.Provider value={contextValue}>
      {children}
    </FastCheckoutCartContext.Provider>
  );
};

// Custom hook to use the context
export const useFastCheckoutCart = () => useContext(FastCheckoutCartContext);
