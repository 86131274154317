import {
  brandclub_colors,
  getPurchaseRewardAmountForProductV2,
} from "@brandclub/common-ui";
import { Box, Divider, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../../../redux/hooks";
import { formatMoney } from "../../../../../utils/StringUtils";
import Loading from "../../../../Loading";
import { FastCheckoutState } from "../../../CheckoutMachine/types/FastCheckoutMachine";
import { getOrderTotals } from "../../../utils";

import { ERROR_MESSAGES } from "../../../../../utils/errors/errorUtils";
import { isDefined } from "../../../../../utils/misc";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";
import { OutlineButton } from "../../../../StoreComponents/StoreButton";
import { StoreError } from "../../../../StoreComponents/StoreError";
import { useFastCheckoutContext } from "../../../CheckoutMachine";
import { ShoppingCartItem } from "../../../types";
import RewardBlock from "../../RewardBlock";
import { TotalLineItem } from "../../TotalLineItem";
import DeliverySection from "./DeliverySection";
import PaymentSection from "./PaymentSection";
import ShippingSection from "./ShippingSection";

import { useSelector } from "@xstate/react";

import AccountSection from "./AccountSection";
import CheckoutHead from "./CheckoutHead";

const getRewardText = (rewardAmount: number, rewardUnlocked: number) => {
  const rewardBlockTitle = `Get cash rewards`;
  let rewardBlockText = ``;
  if (rewardAmount > 0 && rewardUnlocked > 0) {
    rewardBlockText = `Earn ${formatMoney(
      rewardAmount
    )} in new rewards and unlock ${formatMoney(
      rewardUnlocked
    )} in existing rewards on this order.`;
  } else if (rewardAmount > 0) {
    rewardBlockText = `Earn ${formatMoney(
      rewardAmount
    )} in new rewards on this order.`;
  } else if (rewardUnlocked > 0) {
    rewardBlockText = `Unlock ${formatMoney(
      rewardUnlocked
    )} in existing rewards on this order.`;
  }

  return { rewardBlockTitle, rewardBlockText };
};

const TopLevelContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: 73,
  marginBottom: 73,

  ".terms": {
    fontSize: 12,
    marginTop: 20,
    opacity: 0.3,
    textAlign: "center",
    ".tosLinks": {
      color: "unset",
      "&:visited": {},
    },
  },
  ".checkout_stepper": {
    marginBottom: 5,
    marginTop: 0,
  },
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    marginTop: 40,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    marginTop: 40,
  },
});
const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  paddingBottom: 0,

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    paddingBottom: "100px",
    flexDirection: "column",
    gap: 20,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    paddingBottom: "100px",
    flexDirection: "column",
    gap: 20,
  },

  ".checkout_desktop": {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: "none",
    },
  },
  ".checkout_mobile": {
    backgroundColor: "#fff",
    display: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: "20px 20px 35px 20px",
    borderTop: `1px solid ${brandclub_colors.grayLight}`,
    boxSizing: "border-box",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  ".info": {
    flex: 1,
    ".section + .section": {
      borderTop: `1px solid ${brandclub_colors.grayLight}`,
    },
    ".section": {
      padding: "30px 0",
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        padding: "20px 0",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        padding: "20px 0",
      },
      ".section_head": {
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
        position: "relative",
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          marginBottom: 5,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          marginBottom: 5,
        },
        minHeight: 35,
        ".title": {
          fontSize: 20,
          fontWeight: 500,
          flex: 1,
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            fontSize: 14,
            fontWeight: 500,
            opacity: 0.5,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 14,
            fontWeight: 500,
            opacity: 0.5,
          },
        },
        ".action": {
          fontSize: 16,
          fontWeight: 500,

          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        },
      },

      ".info_box": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        ".info_row": {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: "25px",
          display: "flex",
          alignItems: "flex-start",
          "&.address": {
            overflow: "hidden",
            WebkitLineClamp: 1,
            lineHeight: 1.5,
            height: `1.5em`, // line height * 2
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          },
          [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
            fontSize: 14,
          },
          [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            fontSize: 14,
          },
          ".icon": {
            width: 25,
            height: 25,
            marginRight: 8,
            objectFit: "contain",
            marginTop: 2,
            marginBottom: 2,
            [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
              width: 20,
              height: 20,
            },
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              width: 20,
              height: 20,
            },
          },
        },
      },
      ".delivery": {
        marginTop: 10,
      },
      ".shipping_group_list": {
        display: "flex",
        flexDirection: "column",
        gap: 40,
      },
      ".shipping_group": {},
    },
    ".place_order_actions": {
      display: "flex",
      flexDirection: "row",
      gap: 25,
      ".order_total": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ".title": {
          fontSize: 20,
          fontWeight: 600,
        },
        ".subtitle": {
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
  },
  ".summary": {
    // paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    gap: 23,
    maxWidth: 476,
    width: "100%",
    minWidth: 350,
    boxSizing: "border-box",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      minWidth: "unset",
      maxWidth: "unset",
    },
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      minWidth: "unset",
      maxWidth: "unset",
    },
    ".breakdown": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ">*": {
        width: "100%",
      },

      backgroundColor: "#f6f8fa80",
      padding: "30px",
      borderRadius: 8,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        backgroundColor: "#fff",
        padding: "0px",
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        backgroundColor: "#fff",
        padding: "0px",
      },
    },
    ".summary_head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 27,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
        borderTop: `1px solid ${brandclub_colors.grayLight}`,
        paddingTop: 20,
        marginBottom: 10,
      },
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        borderTop: `1px solid ${brandclub_colors.grayLight}`,
        paddingTop: 20,
        marginBottom: 10,
      },
      ".title": {
        fontSize: 20,
        fontWeight: 600,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          fontWeight: 500,
          opacity: 0.5,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
          fontWeight: 500,
          opacity: 0.5,
        },
      },
    },
    ".summary_retailer": {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      ".logo": {
        width: 25,
        height: 25,
        marginRight: 8,
        objectFit: "contain",
      },
    },
  },
});

const EditBagButton = styled(OutlineButton)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  height: 28,
  color: theme.palette.primary.main,
  transition: "all 0.3s",
  "&:hover": {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

const EditPaymentShippingButton = styled(EditBagButton)({
  fontSize: 14,
  height: 35,
  paddingLeft: 23,
  paddingRight: 23,

  right: 0,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    display: "none",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    display: "none",
  },
});

const getErrorMessageFromItems = (items: ShoppingCartItem[]) => {
  const errors = items.map((item) => item.errorMessage);
  const filteredErrors = errors.filter(isDefined);
  if (filteredErrors.length === 0) return undefined;
  // remove duplicates
  const uniqueErrors = Array.from(new Set(filteredErrors));
  // categorize errors
  const outOfStockErrors: string[] = [];
  const productErrors: string[] = [];
  const paymentErrors: string[] = [];
  const otherErrors: string[] = [];
  uniqueErrors.forEach((error) => {
    const errorString = error.toLowerCase();
    if (errorString.includes("out of stock")) {
      outOfStockErrors.push(error);
    } else if (errorString.includes("payment")) {
      paymentErrors.push(error);
    } else if (
      errorString.match(/add \w* to (cart|bag|basket)/i) ||
      errorString.match(/quantity/i)
    ) {
      productErrors.push(error);
    } else {
      otherErrors.push(error);
    }
  });
  // return the first error category that has errors
  if (outOfStockErrors.length > 0) {
    return "Some items in your bag are out of stock and have been removed from your bag.";
  }
  if (paymentErrors.length > 0) {
    return "There was an issue with your payment. Please retry or select another card.";
  }
  if (productErrors.length > 0) {
    return "We encountered an error with some items and have removed them from your bag.";
  }
  if (otherErrors.length > 0) {
    return ERROR_MESSAGES.default[1];
  }
  // if no errors are found, return undefined
  return undefined;
};

export const CheckoutDetail = () => {
  const { actorRef } = useFastCheckoutContext();
  const order = useSelector(
    actorRef,
    (snapshot) => snapshot.context.retailerCheckoutOrderStatus
  );
  const batch = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutBatch
  );

  const status = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutState
  );

  const { products } = useAppSelector((state) => state.checkout);
  const { subtotal, shipping, tax, serviceFee, total, giftCardAmount } =
    getOrderTotals(order?.userRetailerCheckoutOrder);

  const navigate = useNavigate();

  useEffect(() => {
    if (!order && !batch && status !== FastCheckoutState.LOADING) {
      navigate("/mybag");
    }
  }, [order, batch, navigate, status]);

  const items = Object.values(order?.userRetailerCheckoutOrder?.items ?? {});

  const EditBag = () => {
    navigate("/mybag");
  };

  const loadingPage = !order?.userRetailerCheckoutOrder?.retailerId;
  const currentProducts =
    products &&
    order?.userRetailerCheckoutOrder?.items.map((item) => {
      const product = products[item.stacklineSku];
      return {
        ...product,
        quantity: item.quantity,
      };
    });

  const rewardAmount = currentProducts?.reduce((acc, product) => {
    const { amount } = getPurchaseRewardAmountForProductV2(product);
    return acc + amount;
  }, 0);

  const rewardUnlocked = 0;
  const { rewardBlockTitle, rewardBlockText } = getRewardText(
    rewardAmount || 0,
    rewardUnlocked
  );
  const theme = useTheme();

  return (
    <TopLevelContainer>
      <Container gap={12} className="checkout_main_flow_container">
        {loadingPage ? (
          <Box className="info">
            <Loading dot />
          </Box>
        ) : (
          <Box className="info">
            <>
              <CheckoutHead />
              <AccountSection />
              <PaymentSection />
              <ShippingSection />
              <DeliverySection />
            </>
          </Box>
        )}
        <Box className="summary">
          <Box className="breakdown">
            <Box className="summary_head">
              <div className="title">Order summary</div>
              {!theme.dtc && (
                <EditPaymentShippingButton onClick={EditBag}>
                  Edit
                </EditPaymentShippingButton>
              )}
            </Box>
            {/* Total Section */}
            <Box display="flex" flexDirection="column" gap={1}>
              <TotalLineItem label={"Subtotal"} value={subtotal} />
              <TotalLineItem
                label={"Shipping fee"}
                zeroString="Free"
                value={shipping}
              />
              {giftCardAmount ? (
                <TotalLineItem label={"Gift Card"} value={-giftCardAmount} />
              ) : null}
              <TotalLineItem label={"Estimated Tax"} value={tax} />
              <TotalLineItem label={"Service fee"} value={serviceFee} />
              <Divider
                sx={(theme) => ({
                  my: "8px",
                  background: theme.palette.primary.main,
                  height: "0.5px",
                })}
              />
              <TotalLineItem label={"Total"} value={total} />
            </Box>
          </Box>
          <StoreError
            errorMessage={getErrorMessageFromItems(items)}
            sx={{ justifyContent: "center" }}
          />
          {rewardAmount && rewardAmount > 0 ? (
            <RewardBlock title={rewardBlockTitle} text={rewardBlockText} />
          ) : null}
        </Box>
      </Container>
    </TopLevelContainer>
  );
};
