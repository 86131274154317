import { useMachine } from "@xstate/react";
import { ReactNode, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { checkoutUIMachine } from "./checkoutUIMachine";

type CheckoutUIContextType = ReturnType<typeof useMachine<typeof checkoutUIMachine>>;

const CheckoutUIContext = createContext<CheckoutUIContextType | null>(null);

interface CheckoutUIProviderProps {
  children: ReactNode;
}

export const CheckoutUIProvider = ({ children }: CheckoutUIProviderProps) => {
  const navigate = useNavigate();
  
  // Create a function to handle navigation that will be passed to the context
  const handleNavigate = (path: string) => {
    navigate(path);
  };
  
  const machine = useMachine(checkoutUIMachine, {
    input: {
      context: {
        navigate: handleNavigate
      }
    }
  });
  
  return (
    <CheckoutUIContext.Provider value={machine}>
      {children}
    </CheckoutUIContext.Provider>
  );
};

export const useCheckoutUI = () => {
  const context = useContext(CheckoutUIContext);
  if (!context) {
    throw new Error("useCheckoutUI must be used within a CheckoutUIProvider");
  }
  return context;
};
