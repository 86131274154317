import React from "react";
import { styled, Box } from "@mui/material";
import {
  formatMoney,
  getRetailerFromId,
  hexadecimal,
  MOBILE_SCREEN_SIZE,
} from "@brandclub/common-ui";

const SvgContainer = styled("svg")(({ theme }) => ({
  width: 13.5,
  height: 13.5,
  fill: theme.palette.primary.main,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 10,
    height: 10,
  },
}));

const HighlightIcon = () => {
  return (
    <SvgContainer xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 9.978">
      <path d="m9.808 4.725-1.435-.41a3.922 3.922 0 0 1-2.71-2.711L5.253.169a.274.274 0 0 0-.506 0l-.41 1.435a3.922 3.922 0 0 1-2.712 2.711l-1.435.41a.263.263 0 0 0 0 .506l1.435.41a3.922 3.922 0 0 1 2.711 2.711l.41 1.435a.263.263 0 0 0 .506 0l.41-1.435a3.922 3.922 0 0 1 2.711-2.711l1.435-.41a.263.263 0 0 0 0-.506z" />
    </SvgContainer>
  );
};

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: hexadecimal(theme.palette.secondary.main)(40),
  borderRadius: 8,
  flex: 1,

  display: "flex",
  flexDirection: "column",

  "&.reward": {
    padding: 24,
    gap: 16,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 8,
      padding: 18,
    },
  },
  "&.benefit": {
    padding: 24,
    gap: 16,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 10.5,
      padding: 18,
    },
  },

  ".rewards_header": {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },

  ".benefits_header": {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
  ".rewards": {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 4,
    },
  },
  ".benefits": {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  ".benefit-row": {
    display: "flex",
    alignItems: "center",
    gap: 8,

    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
}));

export const AccountConnectRewards = ({
  purchaseAmount,
  connectAmount,
  membershipAmount,
  retailerId,
}: {
  purchaseAmount?: number;
  connectAmount?: number;
  membershipAmount?: number;
  retailerId?: number;
}) => {
  if (!purchaseAmount || !connectAmount || !membershipAmount || !retailerId) {
    return null;
  }

  const retailerName = getRetailerFromId(retailerId);

  const totalAmount =
    (purchaseAmount ?? 0) + (connectAmount ?? 0) + (membershipAmount ?? 0);
  return (
    <Container className="reward">
      <div className="rewards_header">
        Get {formatMoney(totalAmount)} to connect {retailerName}
      </div>
      <div className="rewards">
        {purchaseAmount && (
          <BenefitRow
            text={
              <>
                <strong>{formatMoney(purchaseAmount)}</strong> in purchase
                rewards
              </>
            }
          />
        )}
        {connectAmount && (
          <BenefitRow
            text={
              <>
                <strong>{formatMoney(connectAmount)}</strong> in connect rewards
              </>
            }
          />
        )}
        {membershipAmount && (
          <BenefitRow
            text={
              <>
                <strong>{formatMoney(membershipAmount)}</strong> in membership
                rewards
              </>
            }
          />
        )}
      </div>
    </Container>
  );
};

const BenefitRow = ({
  icon,
  text,
}: {
  icon?: boolean;
  text: string | React.ReactNode;
}) => {
  return (
    <div className="benefit-row">
      {icon && <HighlightIcon />}
      {text}
    </div>
  );
};

export const AccountConnectBenefits = ({
  retailerId,
}: {
  retailerId?: number;
}) => {
  if (!retailerId) {
    return null;
  }
  const retailerName = getRetailerFromId(retailerId);
  return (
    <Container className="benefit">
      <div className="benefits_header">More reasons to connect</div>
      <div className="benefits">
        <BenefitRow icon text="Get fast, free shipping" />
        <BenefitRow icon text={`Use ${retailerName}'s customer service`} />
        <BenefitRow icon text="Easy returns and refunds" />
      </div>
    </Container>
  );
};
