import React, { FC } from "react";
import { Button, ButtonProps } from "@brandclub/common-ui";
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface InfoBoxProps extends Omit<BoxProps, "onClick"> {
  message: string;
  cta?: string;
  onClick?: ButtonProps["onClick"];
}

const InfoBoxMessage = styled("p")(({ theme }) => ({
  margin: 0,
}));

const InfoBoxContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#F6F8FA",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "15px 15px 18px",
  color: theme.palette.primary.main,
  fontSize: "14px",
  boxSizing: "border-box",
}));

const InfoBoxCTA = styled("span")(({ theme }) => ({
  fontWeight: 600,
  fontSize: "inherit",
  marginLeft: "5px",
}));

const InfoBoxAction: FC<ButtonProps> = ({ sx, children, ...props }) => {
  return (
    <Button
      variant="text"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "10px",
        backgroundColor: "transparent",
        width: "fit-content",
        maxWidth: "100%",
        ...sx,
      }}
      {...props}
    >
      <AddCircleOutlineIcon sx={{ fontSize: "15px" }} />
      <InfoBoxCTA>{children}</InfoBoxCTA>
    </Button>
  );
};

const InfoBox: React.FC<InfoBoxProps> = ({
  message,
  cta,
  onClick,
  ...boxProps
}) => {
  return (
    <InfoBoxContainer {...boxProps}>
      <InfoBoxMessage>{message}</InfoBoxMessage>
      {cta ? (
        <InfoBoxAction type="button" onClick={onClick}>
          {cta}
        </InfoBoxAction>
      ) : null}
    </InfoBoxContainer>
  );
};

export default InfoBox;
