import { MOBILE_SCREEN_SIZE } from "@brandclub/common-ui";
import { useMediaQuery } from "@mui/material";
import StoreInput from "../../StoreComponents/StoreInput";

export const CheckoutInputV2 = (
  props: React.ComponentProps<typeof StoreInput>
) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const inputBottomBorder = mobileView
    ? { borderBottom: "1px solid #E0E0E0" }
    : {};
  return (
    <StoreInput
      inputProps={{
        sx: {
          // border: "none",
        },
      }}
      inputStyle={{
        width: "100%",
        maxWidth: "100%",
        minWidth: 200,
        ...inputBottomBorder,
      }}
      {...props}
      variant={mobileView ? "standard" : "filled"}
      textFieldStyle={{ maxWidth: "100%" }}
    />
  );
};
