import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LandingPageWrapper from "../../../pages/AppPages/Pages/components/LandingPageWrapper";
import { FastCheckoutMachineProvider } from "../../CheckoutMachine";
import { CheckoutDetail } from "./CheckoutDetail";

const CheckoutSummary = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const navigate = useNavigate();

  useEffect(() => {
    if (!orderId) {
      console.warn("no order id found in the URL; navigating to purchase");
      navigate("/dashboard/purchase");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, navigate]);

  if (!orderId) {
    return null;
  }

  return (
    <FastCheckoutMachineProvider orderId={orderId}>
      <LandingPageWrapper includeFooter={false}>
        <CheckoutDetail />
      </LandingPageWrapper>
    </FastCheckoutMachineProvider>
  );
};

export default CheckoutSummary;
