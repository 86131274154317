import { formatMoney } from "@/utils/StringUtils";
import { useFastCheckoutCart } from "../../FastCheckoutCartProvider";
import { styled } from "@mui/material";

const TotalContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #dedede",
});

const TotalRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "14px",
  color: "#666",
});

const TotalRowBold = styled(TotalRow)({
  fontSize: "16px",
  fontWeight: 600,
  color: "#000",
  marginTop: "4px",
  paddingTop: "12px",
  borderTop: "1px solid #dedede",
});

export const CartTotal = () => {
  const { currentCart } = useFastCheckoutCart();

  const subtotal =
    currentCart?.items?.reduce(
      (acc, item) => acc + item.retailPrice * item.quantity,
      0
    ) ?? 0;

  return (
    <TotalContainer>
      <TotalRow>
        <span>Subtotal</span>
        <span>{formatMoney(subtotal)}</span>
      </TotalRow>
      <TotalRow>
        <span>Shipping Fee</span>
        <span>{formatMoney(0)}</span>
      </TotalRow>
      <TotalRow>
        <span>Estimated Tax</span>
        <span>TBD</span>
      </TotalRow>
      <TotalRow>
        <span>Service Fee</span>
        <span>{formatMoney(0)}</span>
      </TotalRow>
      <TotalRowBold>
        <span>Total</span>
        <span>{formatMoney(subtotal)}</span>
      </TotalRowBold>
    </TotalContainer>
  );
};
