import { styled } from "@mui/material";
import { useCheckoutUI } from "../../CheckoutUIProvider";
import { useFastCheckoutCart } from "../../FastCheckoutCartProvider";
import { useCheckoutRetailer } from "../../hooks/useCheckoutRetailer";

const DebugContainer = styled("div")({
  padding: "16px",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  marginTop: "20px",
  fontFamily: "monospace",
  fontSize: "12px",
  whiteSpace: "pre-wrap",
});

const DebugSection = styled("div")({
  marginBottom: "12px",
});

const DebugTitle = styled("div")({
  fontWeight: "bold",
  marginBottom: "4px",
  color: "#666",
});

const DebugPanel = () => {
  const [state] = useCheckoutUI();
  const { currentRetailer, amountToCharge } = useFastCheckoutCart();
  const clientSecret = state.context.clientSecret;

  const { retailerSyncStatus } = useCheckoutRetailer();

  const { retailerCredentials, isRetailerConnected } = state.context;
  return (
    <DebugContainer>
      <DebugSection>
        <DebugTitle>State Machine State</DebugTitle>
        <div>Current State: {JSON.stringify(state.value)}</div>
        <div>
          Open Sections: {JSON.stringify(state.context.openSections, null, 2)}
        </div>
      </DebugSection>

      <DebugSection>
        <DebugTitle>Checkout Context</DebugTitle>
        <div>Current Retailer: {currentRetailer?.displayName || "None"}</div>
        <div>Amount to Charge: {amountToCharge || "Not set"}</div>
        <div>Client Secret: {clientSecret ? "Set" : "Not set"}</div>
      </DebugSection>

      <DebugSection>
        <DebugTitle>Retailer Status</DebugTitle>
        <div>Connected: {isRetailerConnected ? "Yes" : "No"}</div>
        <div>
          Retailer Credentials: {retailerCredentials ? "Set" : "Not set"}
        </div>
        <div>Sync Status: {retailerSyncStatus?.syncStatus || "None"}</div>
        {retailerSyncStatus?.errorMessage && (
          <div>Error: {retailerSyncStatus.errorMessage}</div>
        )}
      </DebugSection>

      <DebugSection>
        <DebugTitle>Available Actions</DebugTitle>
        <div>
          NEXT: {state.can({ type: "NEXT" }) ? "Available" : "Not available"}
        </div>
        <div>
          BACK: {state.can({ type: "BACK" }) ? "Available" : "Not available"}
        </div>
        <div>
          SUBMIT:{" "}
          {state.can({ type: "SUBMIT" }) ? "Available" : "Not available"}
        </div>
      </DebugSection>
    </DebugContainer>
  );
};

export default DebugPanel;
