import { SolidButton } from "@/components/StoreComponents/StoreButton";
import { styled } from "@mui/material";
import { CartProduct } from "../../../CartProduct";
import { useFastCheckoutCart } from "../../FastCheckoutCartProvider";
import { CartTotal } from "./CartTotal";
import { useCheckoutUI } from "../../CheckoutUIProvider";

const CartContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const CartTitle = styled("div")({
  fontSize: "18px",
  fontWeight: 600,
  color: "#000",
});

export const CurrentCart = () => {
  const { currentCart } = useFastCheckoutCart();
  const [state, send] = useCheckoutUI();

  const cartItems = currentCart?.items;

  if (!cartItems?.length) {
    return null;
  }

  return (
    <CartContainer>
      <CartTitle>Current Cart</CartTitle>
      {cartItems.map((item) => (
        <CartProduct
          key={item.stacklineSku}
          product={item}
          disabled={state.value === "payment_success"}
        />
      ))}
      <CartTotal />
      <SolidButton
        disabled={state.value !== "payment_success"}
        onClick={() => send({ type: "NEXT" })}
      >
        Submit my order
      </SolidButton>
    </CartContainer>
  );
};
