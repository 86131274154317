import { Box, styled } from "@mui/material";
import {
  ChevronDownIcon,
  getEntityImage,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "@brandclub/common-ui";
import { StoreAnimatedHeightDiv } from "../../../../StoreComponents/StoreAnimatedHeightDiv";
import { AnimatePresence, motion } from "framer-motion";

import React from "react";
import { StoreErrorV2 } from "@/components/StoreComponents/StoreError";
import {
  AccountConnectBenefits,
  AccountConnectRewards,
} from "../components/AccountRewards";
import { useFastCheckoutContext } from "@/components/Checkout/CheckoutMachine";
import { useSelector } from "@xstate/react";
import { CheckoutInputV2 } from "../../CheckoutInputV2";

const AccountPreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  gap: "10px",
  color: theme.palette.primary.main,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    fontSize: "14px",
    gap: "7px",
  },
  ".logo": {
    width: "33px",
    height: "33px",
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: "20px",
      height: "20px",
    },
  },
}));

const AccountPreview = () => {
  const { actorRef } = useFastCheckoutContext();

  const retailerUsername = useSelector(
    actorRef,
    (state) =>
      state.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.userInfo?.retailerUsername
  );
  const retailerId = useSelector(
    actorRef,
    (state) =>
      state.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.retailerId
  );
  const retailerAccount = retailerUsername ?? "Unknown";

  return (
    <AccountPreviewContainer>
      {retailerId ? (
        <img
          alt="logo"
          className="logo"
          src={getEntityImage(retailerId, "retailer")}
        />
      ) : null}
      <div className="title">{retailerAccount}</div>
    </AccountPreviewContainer>
  );
};

const UpdateUserNamePasswordContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "16px",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    flexDirection: "column",
    gap: "15px",
  },
}));

const UpdateUserNamePassword = () => {
  return (
    <UpdateUserNamePasswordContainer>
      <CheckoutInputV2 label={"Email"} />
      <CheckoutInputV2 label={"Password"} />
    </UpdateUserNamePasswordContainer>
  );
};

// const OTP = () => {
//   return (
//     <div>
//       <div>OTP</div>
//     </div>
//   );
// };

const RewardExpandContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: 16,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    gap: 8,
    flexDirection: "column",
  },
}));

const AccountSection = () => {
  const { actorRef } = useFastCheckoutContext();
  const retailerId = useSelector(
    actorRef,
    (state) =>
      state.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.retailerId
  );
  const accountSectionState = useSelector(
    actorRef,
    (state) => state.context.sectionState.account
  );

  const { open, chevronShow, errorMessage } = accountSectionState;

  const toggleOpen = () => {
    console.log("toggleOpen", open);
    if (open) {
      actorRef.send({
        type: "ON_SESSION_CLOSE",
        data: { sessionToClose: "account" },
      });
    } else {
      actorRef.send({
        type: "ON_SESSION_OPEN",
        data: { sessionToOpen: "account" },
      });
    }
  };
  return (
    <Box className="section">
      <div className="section_head" style={{ cursor: "pointer" }}>
        <div className="title">{"Account Method"}</div>
        {chevronShow ? (
          <ChevronDownIcon
            style={{
              transition: "transform 0.3s",
              width: "33px",
              height: "33px",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={toggleOpen}
          ></ChevronDownIcon>
        ) : null}
      </div>
      <StoreAnimatedHeightDiv>
        <StoreErrorV2
          errorMessage={errorMessage}
          sx={{
            width: "fit-content",
            marginTop: "2.2px",
            marginBottom: "15px",
          }}
        />
        <AccountPreview />
        <UpdateUserNamePassword />

        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={open ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {open ? (
              <RewardExpandContainer>
                <AccountConnectRewards
                  retailerId={retailerId}
                  purchaseAmount={3}
                  connectAmount={2}
                  membershipAmount={1}
                />
                <AccountConnectBenefits retailerId={retailerId} />
              </RewardExpandContainer>
            ) : (
              <div>reward summary pill</div>
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default AccountSection;
