import React from "react";
import CheckoutFlow from "../Checkout/components/CheckoutFlow";
import { StoreNavAction } from "../StoreComponents/StoreSinglePageOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import { Navigate } from "react-router-dom";
import CheckoutDetail from "../Checkout/components/CheckoutV2/CheckoutDetail";
import Loading from "../Loading";
import { AppSuspense } from "../AppSuspense";
import CheckSummary from "../Checkout/components/CheckoutSummary";

const ContentFlow = React.lazy(
  () => import("../pages/AppPages/Pages/Content/flow")
);
const ReviewFlow = React.lazy(
  () => import("../pages/AppPages/Pages/Review/flow")
);
const SurveyFlow = React.lazy(
  () => import("../pages/AppPages/Pages/Survey/flow")
);

const JoinBrandStoreBrandClubSuccess = React.lazy(
  () => import("../pages/AppPages/BrandStore/JoinBrandstoreBrandclubSuccess")
);
const MembershipProgram = React.lazy(
  () => import("../pages/AppPages/MembershipProgram")
);

export const singlePageCustomCloseRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "joinclubsuccess/:brandId",
    index: true,
    element: <JoinBrandStoreBrandClubSuccess />,
  },
];
export const membershipProgramRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "membership/",
    index: true,
    element: <MembershipProgram />,
    handle: {
      pageNavConfig: {
        // hasViewBagButton: true,
      },
    },
  },
];

export const dtcCheckoutRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "checkout/",
    element: <CheckoutFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBag,
      },
    },
    pageType: "Checkout",
  },
  {
    // for backward compatibility
    path: "entercheckout/",
    element: <Navigate to="/checkout" replace />,
  },
];

export const dtcSinglePageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "content/:campaignId",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <ContentFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Content",
  },
  {
    path: "survey/:campaignId",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <SurveyFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Survey",
  },
  {
    path: "review/:stacklineSku",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <ReviewFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Review",
  },
];

export const singlePageRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "checkoutv2/",
    element: <CheckoutDetail />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBag,
      },
    },
  },
  {
    path: "checkoutSummary/",
    element: <CheckSummary />, // TODO: rename --- this is the processing
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBag,
      },
    },
  },
  {
    path: "checkout/",
    element: <CheckoutFlow />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToBag,
      },
    },
    pageType: "Checkout",
  },
  {
    // for backward compatibility
    path: "entercheckout/",
    element: <Navigate to="/checkout" replace />,
  },
  {
    path: "content/:campaignId",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <ContentFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Content",
  },
  {
    path: "survey/:campaignId",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <SurveyFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Survey",
  },
  {
    path: "review/:stacklineSku",
    element: (
      <AppSuspense fallback={<Loading dot></Loading>}>
        <ReviewFlow />
      </AppSuspense>
    ),
    handle: {
      pageNavConfig: {
        close: StoreNavAction.Back,
      },
    },
    pageType: "Review",
  },
];
