import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { ShippingGroupPreview } from "../ShippingGroupPreview";

import { useFastCheckoutContext } from "../../../CheckoutMachine";

import { ChevronDownIcon } from "@brandclub/common-ui";
import { ShoppingCartItem } from "../../../types";
import { SummaryProduct } from "../../SummaryProductV2";
import { useSelector } from "@xstate/react";

export const getErrorProducts = (items: ShoppingCartItem[]) => {
  let res: ShoppingCartItem[] = items;
  res = res.filter(
    (item) => item.status === "error" && item?.userApprovalStatus !== "deleted"
  );
  return res;
};

const DeliverySection = () => {
  const { actorRef, send } = useFastCheckoutContext();
  const open = useSelector(
    actorRef,
    (state) => state.context.sectionState.delivery.open
  );
  const chevronShow = useSelector(
    actorRef,
    (state) => state.context.sectionState.delivery.chevronShow
  );

  const toggleOpen = () => {
    if (open) {
      send({ type: "ON_SESSION_CLOSE", data: { sessionToClose: "delivery" } });
    } else {
      send({ type: "ON_SESSION_OPEN", data: { sessionToOpen: "delivery" } });
    }
  };

  const userRetailerCheckoutOrder = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
  );
  const shippingGroups =
    userRetailerCheckoutOrder?.shippingOptions?.shippingGroups;
  const [openOptions, setOpenOptions] = useState<boolean[]>([]);

  useEffect(() => {
    const length = shippingGroups?.length ?? 0;
    setOpenOptions(new Array(length).fill(open));
  }, [open, shippingGroups?.length]);

  const items = userRetailerCheckoutOrder?.items;

  const errorProducts = getErrorProducts(items ?? []);

  const handleUpdateShippingOption = async (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => {
    try {
      // checkoutSend({
      //   type: "CHECKOUT_USER_INPUT_EVENT",
      //   inputEvent: UserInputUpdateType.UPDATE_SHIPPING_OPTION,
      //   data: {
      //     deliveryOption,
      //     deliveryTimeWindow,
      //   },
      // });
      console.log("todo: deliveryOption", deliveryOption);
      console.log("todo: deliveryTimeWindow", deliveryTimeWindow);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box className="section">
      <div className="section_head" style={{ cursor: "pointer" }}>
        <div className="title">Delivery</div>
        {chevronShow ? (
          <ChevronDownIcon
            style={{
              transition: "transform 0.3s",
              width: "33px",
              height: "33px",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={toggleOpen}
          ></ChevronDownIcon>
        ) : null}
      </div>
      <div className="shipping_group_list">
        {shippingGroups?.map((e, index) => {
          return (
            <ShippingGroupPreview
              key={index}
              shippingGroup={e}
              index={index}
              handleUpdateShippingOption={handleUpdateShippingOption}
              deliveryOpen={openOptions}
              openItem={(index: number) => {
                const newOpenOptions = [...openOptions];
                newOpenOptions[index] = !newOpenOptions[index];
                setOpenOptions(newOpenOptions);
              }}
            />
          );
        })}
        {errorProducts.length > 0 ? (
          <>
            {errorProducts.map((errorProduct) => {
              return (
                <SummaryProduct
                  product={errorProduct}
                  key={errorProduct.stacklineSku}
                />
              );
            })}
          </>
        ) : null}
      </div>
    </Box>
  );
};

export default DeliverySection;
