import { createContext, useContext, ReactElement } from "react";
import { useActor } from "@xstate/react";
import { ActorRefFrom, ActorLogicFrom } from "xstate";
import { checkoutMachine } from "./CheckoutMachine";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import ServerSideCheckoutPoll from "../ServerSideCheckoutPoll";
import { getAllCarts } from "@/redux/selectors";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";

type CheckoutContextType = {
  send: ActorRefFrom<ActorLogicFrom<typeof checkoutMachine>>["send"];
  actorRef: ActorRefFrom<typeof checkoutMachine>;
};

export const CheckoutContext = createContext<CheckoutContextType | null>(null);

export const CheckoutProvider = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allCarts = useAppSelector(getAllCarts, _isEqual);
  const retailerIds = allCarts.map((cart) => cart.retailerId);
  const retailerConnectionInfoList = useAppSelector(
    (state) =>
      state.retailers.map((retailer) => {
        const status = _get(state.userProfile, [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ]);
        return {
          retailerId: retailer.parentRetailerId,
          credential: {
            connectionStatus: status,
          },
        };
      }),
    _isEqual
  );

  const actor = useActor(checkoutMachine, {
    input: {
      context: {
        checkoutAllCarts: allCarts,
        navigate,
        dispatch,
        retailerIds,
        retailerConnectionInfoList,
        activeCheckoutRetailerId: retailerIds[0],
      },
    },
  });

  const [, send, actorRef] = actor;

  return (
    <CheckoutContext.Provider
      value={{
        send,
        actorRef,
      }}
    >
      <ServerSideCheckoutPoll />
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  const checkoutContext = useContext(CheckoutContext);
  if (!checkoutContext) {
    throw new Error(
      "useCheckoutContext must be used within a CheckoutProvider"
    );
  }
  return checkoutContext;
};
