import { useEffect } from "react";
import GenericDialogNoClose, {
  DialogButtonAction,
} from "../../../StoreComponents/StoreDialogs/GenericDialogNoClose";
import { useCheckoutContext } from "../../CheckoutMachine/CheckoutProvider";
import { useNavigate } from "react-router-dom";
import { getRetailerFromId } from "@brandclub/common-ui";
import { useIsAllOutOfStock } from "./AllOutOfStockScreen";
import { useSelector } from "@xstate/react";

const CheckoutErrorDialog = (props: { handleReset: VoidFunction }) => {
  const { allOutOfStock, isSingleRetailerCheckout } = useIsAllOutOfStock();
  const { send: checkoutSend, actorRef } = useCheckoutContext();
  const batch = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutBatch
  );
  const navigate = useNavigate();
  const retailerCheckoutOrderStatus = useSelector(
    actorRef,
    (snapshot) => snapshot.context.retailerCheckoutOrderStatus
  );
  const orderStatus =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status;
  const hasError = useSelector(actorRef, (snapshot) =>
    Boolean(snapshot.context.error?.message)
  );
  const shouldShowError =
    orderStatus === "checkout_timed_out" ||
    orderStatus === "checkout_error" ||
    orderStatus === "checkout_cancelled" ||
    hasError;

  const errorMessage =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.errorMessage;

  const goToBag = () => {
    navigate("/mybag");
  };

  const isTimeOutError = errorMessage === "Timeout waiting for user input";

  const shouldShowGenericError =
    shouldShowError && !isTimeOutError && !allOutOfStock;

  const currentRetailerId =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.retailerId ?? 0;
  const index = batch?.retailerIds.indexOf(currentRetailerId);

  const nextRetailerId = batch?.retailerIds?.[(index ?? 0) + 1];

  const timeOutErrorTitle = "Your order has timed out";
  const timeOutErrorDescription =
    "We need to confirm your products are in stock again to process this order.";

  const genericErrorTitle = "Let’s try that again";
  const genericErrorDescription =
    "We’ve encountered an issue trying to process your order. Please make a selection below.";

  const errorTitle = isTimeOutError ? timeOutErrorTitle : genericErrorTitle;
  const errorDescription = isTimeOutError
    ? timeOutErrorDescription
    : genericErrorDescription;

  const handleOpen = () => {
    const actions: DialogButtonAction[] = [];

    actions.push({
      variant: "outline",
      text: "Back to my bag",
      onClick: goToBag,
    });

    if (nextRetailerId) {
      actions.push({
        variant: "solid",
        text: `Checkout ${getRetailerFromId(nextRetailerId)} order`,
        onClick: () => {
          checkoutSend({
            type: "CHECKOUT_NEXT_RETAILER",
          });
        },
      });
    } else {
      actions.push({
        variant: "solid",
        text: "Refresh my order",
        onClick: props.handleReset,
      });
    }
    GenericDialogNoClose.show({
      title: errorTitle,

      trackingParams: {
        errorMessage,
        order: retailerCheckoutOrderStatus,
      },
      text: errorDescription,
      actions: actions,
    });
  };

  const handleOpenAllOutOfStock = () => {
    const actions: DialogButtonAction[] = [];
    if (nextRetailerId) {
      actions.push({
        variant: "solid",
        text: `Continue ${getRetailerFromId(nextRetailerId)} order`,
        onClick: () => {
          checkoutSend({
            type: "CHECKOUT_NEXT_RETAILER",
          });
        },
      });
    }
    actions.push({
      variant: "outline",
      text: "Back to my bag",
      onClick: goToBag,
    });

    GenericDialogNoClose.show({
      title: "Out of stock items",

      trackingParams: { order: retailerCheckoutOrderStatus },
      text: `Your order from ${getRetailerFromId(
        retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
          ?.retailerId as number
      )} couldn’t be processed because all items were out of stock.`,
      actions: actions,
    });
  };
  const handleClose = () => {
    GenericDialogNoClose.hide();
  };

  useEffect(() => {
    if (shouldShowGenericError || isTimeOutError) {
      handleOpen();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowGenericError, isTimeOutError]);

  useEffect(() => {
    if (allOutOfStock && !isSingleRetailerCheckout) {
      handleOpenAllOutOfStock();
    } else if (!isSingleRetailerCheckout) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOutOfStock, isSingleRetailerCheckout]);

  return <></>;
};

export default CheckoutErrorDialog;
