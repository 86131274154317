import { createContext, useContext, ReactElement } from "react";
import { useActor } from "@xstate/react";
import { ActorRefFrom, ActorLogicFrom } from "xstate";
import { fastCheckoutMachine } from "./FastCheckoutMachine";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { getAllCarts } from "@/redux/selectors";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import ServerSideCheckoutStatusPollerV2 from "../ServerSideCheckoutPollV2";

type FastCheckoutContextType = {
  send: ActorRefFrom<ActorLogicFrom<typeof fastCheckoutMachine>>["send"];
  actorRef: ActorRefFrom<typeof fastCheckoutMachine>;
};

export const FastCheckoutContext =
  createContext<FastCheckoutContextType | null>(null);

export const FastCheckoutMachineProvider = ({
  children,
  orderId,
}: {
  children: ReactElement;
  orderId: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allCarts = useAppSelector(getAllCarts, _isEqual);
  const retailerIds = allCarts.map((cart) => cart.retailerId);
  const retailerConnectionInfoList = useAppSelector(
    (state) =>
      state.retailers.map((retailer) => {
        const status = _get(state.userProfile, [
          "extendedAttributes",
          `connectedRetailer_${retailer.parentRetailerId}_state`,
        ]);
        return {
          retailerId: retailer.parentRetailerId,
          credential: {
            connectionStatus: status,
          },
        };
      }),
    _isEqual
  );

  const actor = useActor(fastCheckoutMachine, {
    input: {
      context: {
        sectionState: {
          account: {
            open: false,
            chevronShow: true,
            errorMessage: "",
          },
          payment: {
            open: false,
            chevronShow: true,
            errorMessage: "",
          },
          shipping: {
            open: false,
            chevronShow: true,
            errorMessage: "",
          },
          delivery: {
            open: false,
            chevronShow: true,
            errorMessage: "",
          },
        },
        checkoutAllCarts: allCarts,
        navigate,
        dispatch,
        retailerIds,
        retailerConnectionInfoList,
        activeCheckoutRetailerId: retailerIds[0],
      },
    },
  });

  const [, send, actorRef] = actor;

  return (
    <FastCheckoutContext.Provider
      value={{
        send,
        actorRef,
      }}
    >
      <ServerSideCheckoutStatusPollerV2 />
      {children}
    </FastCheckoutContext.Provider>
  );
};

export const useFastCheckoutContext = () => {
  const fastCheckoutContext = useContext(FastCheckoutContext);
  if (!fastCheckoutContext) {
    throw new Error(
      "useFastCheckout must be used within a FastCheckoutProvider"
    );
  }
  return fastCheckoutContext;
};
