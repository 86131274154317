import axios from "../../../utils/axios";
import { getAuthorizationHeader } from "../../../Auth";

export const autocompleteAddress = async (
  req: {
    searchText: string;
    countryCodes: string[];
    location?: {
      latitude: number;
      longitude: number;
    };
  },
  abortSignal?: AbortController
) => {
  const res = await axios.post("/userAddress/autocompleteAddress", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
    signal: abortSignal?.signal,
  });
  return res;
};

export const getFullAddressFromPlaceId = async (req: { placeId: string }) => {
  const res = await axios.post("/userAddress/getFullAddressFromPlaceId", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  return res;
};

export interface AddressValidationResponse {
  status: "success" | "failed";
  validationResult?: {
    result: {
      verdict: {
        inputGranularity: string;
        validationGranularity: string;
        geocodeGranularity: string;
        addressComplete: boolean;
        hasInferredComponents: boolean;
        hasReplacedComponents: boolean;
      };
      address: {
        formattedAddress: string;
        postalAddress: {
          regionCode: string;
          languageCode: string;
          postalCode: string;
          administrativeArea: string;
          locality: string;
          addressLines: string[];
        };
      };
    };
    responseId: string;
  };
  isValid?: boolean;
  errorMessage?: string;
}

export const validateAddress = async (req: {
  address1: string;
  address2?: string;
  city: string;
  state?: string;
  zipCode?: string;
  countryCode?: string;
  enableUspsCass?: boolean; // Verifies whether an address is a valid delivery point
  previousResponseId?: string;
}) => {
  const { data } = await axios.post("/userAddress/validateAddress", req, {
    headers: {
      ...(await getAuthorizationHeader()),
    },
  });
  return data as AddressValidationResponse;
};
