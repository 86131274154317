import { validateAddress } from "@/api/rest/authenticated/Address";
import StoreAddressAutocompleteInput, {
  autocompleteAddressType,
} from "@/components/StoreComponents/StoreAddressAutocompleteInput";
import {
  OutlineButton,
  SolidButton,
} from "@/components/StoreComponents/StoreButton";
import GenericDialog from "@/components/StoreComponents/StoreDialogs/GenericDialog";
import { StoreError } from "@/components/StoreComponents/StoreError";
import StoreInput from "@/components/StoreComponents/StoreInput";
import { useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";
import { useCheckoutUI } from "../../CheckoutUIProvider";
import { useFastCheckoutCart } from "../../FastCheckoutCartProvider";
import { Box } from "@mui/material";

export const ShippingMethodForm = () => {
  const [machineState, send] = useCheckoutUI();
  const { amountToCharge } = useFastCheckoutCart();
  const userProfile = useAppSelector((state) => state.userProfile);
  const addresses = userProfile?.addresses;
  const [validating, setValidating] = useState(false);
  const [addressError, setAddressError] = useState<string | null>(null);

  const defaultShippingAddress = addresses?.find(
    (address) => address.id === userProfile?.defaultShippingAddressId
  );

  const { shipping } = machineState.context;

  const { firstName, lastName, address1, address2, city, state, zipCode } =
    shipping || {};

  useEffect(() => {
    if (defaultShippingAddress) {
      const { firstName, lastName, address1, address2, city, state, zipCode } =
        defaultShippingAddress;

      send({
        type: "SET_SHIPPING",
        data: { firstName, lastName, address1, address2, city, state, zipCode },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShippingAddress, send]);

  const handleContinue = async () => {
    // Clear previous errors
    setAddressError(null);

    // Basic form validation
    if (!firstName || !lastName || !address1 || !city || !state || !zipCode) {
      setAddressError("Please fill in all required address fields");
      return;
    }

    setValidating(true);

    try {
      // Validate the address with the API
      const validationResult = await validateAddress({
        address1,
        address2: address2 || undefined,
        city,
        state,
        zipCode,
        countryCode: "US", // Assuming US as default
      });

      setValidating(false);

      if (validationResult.status === "failed" || !validationResult.isValid) {
        setAddressError(
          validationResult.errorMessage ||
            "Invalid address. Please check your address details."
        );
        return;
      }

      // Check if the validated address is different from the input
      const formattedAddress =
        validationResult.validationResult?.result?.address?.formattedAddress;
      const formattedAddressLines =
        validationResult.validationResult?.result?.address?.postalAddress
          ?.addressLines || [];
      const formattedCity =
        validationResult.validationResult?.result?.address?.postalAddress
          ?.locality;
      const formattedState =
        validationResult.validationResult?.result?.address?.postalAddress
          ?.administrativeArea;
      const formattedZipCode =
        validationResult.validationResult?.result?.address?.postalAddress
          ?.postalCode;

      const isAddressDifferent =
        (formattedAddressLines.length > 0 &&
          formattedAddressLines[0].toLowerCase() !== address1.toLowerCase()) ||
        (formattedAddressLines.length > 1 &&
          formattedAddressLines[1].toLowerCase() !==
            (address2 || "").toLowerCase()) ||
        (formattedCity && formattedCity.toLowerCase() !== city.toLowerCase()) ||
        (formattedState &&
          formattedState.toLowerCase() !== state.toLowerCase()) ||
        (formattedZipCode && formattedZipCode !== zipCode);

      if (isAddressDifferent && formattedAddress) {
        // Show dialog to confirm which address to use
        GenericDialog.show({
          title: "Address Suggestion",
          text: `We found a more accurate format for your address. Would you like to use the suggested address?

Original
${address1}${address2 ? `\n${address2}` : ""}
${city}, ${state} ${zipCode}

Suggested
${formattedAddressLines[0] || ""}${
            formattedAddressLines.length > 1
              ? `\n${formattedAddressLines[1]}`
              : ""
          }
${formattedCity || city}, ${formattedState || state} ${
            formattedZipCode || zipCode
          }`,
          textProps: {
            sx: {
              whiteSpace: "pre-line",
              fontFamily: "monospace",
              fontSize: "14px",
              lineHeight: "1.5",
            },
          },
          actions: [
            {
              text: "Use Original",
              variant: "outline",
              onClick: () => {
                proceedToPayment();
              },
            },
            {
              text: "Use Suggested",
              variant: "solid",
              onClick: () => {
                // Update shipping with formatted address
                if (formattedAddressLines.length > 0) {
                  const updatedShipping = {
                    ...shipping,
                    address1: formattedAddressLines[0] || address1,
                    address2:
                      formattedAddressLines.length > 1
                        ? formattedAddressLines[1]
                        : "",
                    city: formattedCity || city,
                    state: formattedState || state,
                    zipCode: formattedZipCode || zipCode,
                  };

                  send({
                    type: "SET_SHIPPING",
                    data: updatedShipping,
                  });
                }
                proceedToPayment();
              },
            },
          ],
        });
      } else {
        proceedToPayment();
      }
    } catch (error) {
      setValidating(false);
      setAddressError(
        "An error occurred while validating your address. Please try again."
      );
      console.error("Address validation error:", error);
    }
  };

  const proceedToPayment = () => {
    if (machineState.value !== "shipping") {
      send({ type: "EDIT", section: "shipping" });
      return;
    }

    if (amountToCharge) {
      send({ type: "SET_AMOUNT", data: amountToCharge });
    }

    send({ type: "NEXT" });
  };

  const updateShipping = (data: any) => {
    // Clear error when user updates any field
    setAddressError(null);
    send({ type: "SET_SHIPPING", data });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: "10px",
        }}
      >
        <StoreInput
          label="First name"
          value={firstName}
          onChange={(e) =>
            updateShipping({ ...shipping, firstName: e.target.value })
          }
          sx={{ maxWidth: "100%", flex: 1 }}
          required
        />
        <StoreInput
          label="Last name"
          value={lastName}
          onChange={(e) =>
            updateShipping({ ...shipping, lastName: e.target.value })
          }
          sx={{ maxWidth: "100%", flex: 1 }}
          required
        />
      </Box>
      <StoreAddressAutocompleteInput
        searchTerm={address1}
        selectAddressCallback={(address: autocompleteAddressType) => {
          updateShipping({
            ...shipping,
            address1: address?.line1 || "",
            address2: address?.line2 || "",
            city: address?.city || "",
            state: address?.state || "",
            zipCode: address?.zipCode || "",
          });
        }}
        renderInput={(param: any) => (
          <StoreInput
            {...param}
            placeholder="Begin typing to search"
            label={"Address"}
            onChange={(e) =>
              updateShipping({ ...shipping, address1: e.target.value })
            }
            value={address1}
            InputProps={{
              ...param.InputProps,
            }}
            inputProps={{
              ...param.inputProps,
              value: address1,
            }}
            sx={{ maxWidth: "100%" }}
            required
          />
        )}
      />

      <StoreInput
        label="Address 2"
        value={address2}
        onChange={(e) =>
          updateShipping({ ...shipping, address2: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
      />

      <StoreInput
        label="City"
        value={city}
        onChange={(e) => updateShipping({ ...shipping, city: e.target.value })}
        sx={{ maxWidth: "100%" }}
        required
      />
      <StoreInput
        label="State"
        value={state}
        onChange={(e) => updateShipping({ ...shipping, state: e.target.value })}
        sx={{ maxWidth: "100%" }}
        required
      />
      <StoreInput
        label="Zip code"
        value={zipCode}
        onChange={(e) =>
          updateShipping({ ...shipping, zipCode: e.target.value })
        }
        sx={{ maxWidth: "100%" }}
        required
      />

      {addressError && <StoreError errorMessage={addressError} />}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <OutlineButton
          onClick={() => {
            updateShipping({ address1: "" });
          }}
          style={{ marginTop: "16px" }}
        >
          Clear
        </OutlineButton>
        <SolidButton
          onClick={handleContinue}
          style={{ marginTop: "16px" }}
          disabled={validating}
        >
          {validating ? "Saving" : "Save"}
        </SolidButton>
      </div>
    </div>
  );
};
