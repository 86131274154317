import { brandclub_colors, brandclubStyles } from "@brandclub/common-ui";
import { Dialog, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { create } from "zustand";
import { TABLET_SCREEN_SIZE } from "../../AppNavigation/constants";
import Loading from "../../Loading";
import { OutlineButton } from "../../StoreComponents/StoreButton";
import { useCheckoutContext } from "@/components/Checkout/CheckoutMachine/CheckoutProvider";
import { UserRetailerCheckoutOrderStatus } from "../types";
import { useSelector } from "@xstate/react";

interface BearState {
  orderId: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".container": {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    color: theme.palette.primary.main,
    padding: "117px 63px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    ".title": {
      fontSize: 32,
      fontWeight: 700,
      textAlign: "center",
      marginBottom: 10,
    },
    ".subtitle": {
      fontSize: 24,
      fontWeight: 500,
      textAlign: "center",
      marginBottom: 10,
    },
  },

  "& div.MuiDialog-paper": {
    margin: 0,
    borderRadius: 10,
    minWidth: 789,
    maxWidth: 789,
    minHeight: 211,
    color: theme.palette.primary.main,
  },
  "& h2.MuiDialogTitle-root": {
    padding: 16,
  },

  "& .dialog-content": {
    padding: 0,
    overflowY: "auto",
    color: theme.palette.primary.main,
    ...brandclubStyles.customScrollbar,
  },
  // media queries
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    "& div.MuiDialog-paper": {
      width: "100vw",
      minWidth: "100vw",
      maxWidth: "100vw",
      maxHeight: "75vh",
      position: "absolute",
      bottom: 0,
    },
  },
}));

const useConfirmDialogStore = create<BearState>(() => ({
  orderId: "",
}));

const CloseButton = styled(OutlineButton)({
  fontSize: 16,
  height: 48,
  fontWeight: 600,
});
const CheckoutTransition = () => {
  const { actorRef } = useCheckoutContext();
  const checkoutBatchId = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutBatch?.checkoutBatchId
  );
  const currentRetailerId = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.retailerId ?? 0
  );
  const batchRetailerIds = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutBatch?.retailerIds
  );
  const index = batchRetailerIds?.indexOf(currentRetailerId);
  const hasNextRetailerToCheckout =
    index !== -1 && index !== (batchRetailerIds?.length || 0) - 1;
  const retailerCheckoutOrderStatus = useSelector(
    actorRef,
    (snapshot) => snapshot.context.retailerCheckoutOrderStatus
  );
  const orderStatus =
    retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status;
  const sessionStatus =
    retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus?.status;
  const { orderId } = useConfirmDialogStore();
  const batchOrderStatusRef =
    useRef<{
      [orderId: string]: UserRetailerCheckoutOrderStatus;
    }>();

  const { pathname, search } = useLocation();

  const isAtCheckoutPage = pathname === "/checkout" && search.includes(orderId);

  const navigate = useNavigate();
  const statusMessage =
    retailerCheckoutOrderStatus?.displayStatusInfo?.title ?? "";
  const statusMessageDescription =
    retailerCheckoutOrderStatus?.displayStatusInfo?.description ?? "";

  const shouldMoveToCheckout =
    orderStatus === "checkout_in_progress" &&
    sessionStatus === "checkout_waiting_for_user_input";

  const shouldSuccess = orderStatus === "checkout_success";

  const shouldShowError =
    orderStatus === "checkout_timed_out" ||
    orderStatus === "checkout_error" ||
    orderStatus === "checkout_cancelled";

  useEffect(() => {
    if (orderId) {
      if (shouldMoveToCheckout) {
        closeDialog();
        if (!isAtCheckoutPage) {
          navigate({
            pathname: "checkout",
            search: createSearchParams({
              orderId,
            }).toString(),
          });
        }
      } else if (shouldShowError) {
        navigate("/mybag");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtCheckoutPage, orderId]);

  const closeDialog = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
  };

  const handleSuccess = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
    navigate(
      {
        pathname: "checkoutsuccess",
        search: createSearchParams({
          batchId: checkoutBatchId ?? "",
        }).toString(),
      },
      { replace: true }
    );
  };

  const handleError = () => {
    useConfirmDialogStore.setState({
      orderId: "",
    });
    const hasAtLeastOneSuccessfulCheckout = Object.values(
      batchOrderStatusRef.current ?? {}
    ).some((status) => status === "checkout_success");
    if (hasAtLeastOneSuccessfulCheckout) {
      navigate(
        {
          pathname: "checkoutsuccess",
          search: createSearchParams({
            batchId: checkoutBatchId ?? "",
          }).toString(),
        },
        { replace: true }
      );
    } else {
      navigate("/mybag", { replace: true });
    }
  };
  const theme = useTheme();

  return (
    <StyledDialog
      open={orderId !== ""}
      BackdropProps={{
        style: {
          backgroundColor: theme.dtc
            ? `${theme.palette.primary.main}0D`
            : brandclub_colors.darkBlue3,
        },
      }}
    >
      {orderStatus ? (
        <div className="container">
          <div className="title">{statusMessage}</div>
          <div className="subtitle">{statusMessageDescription}</div>
          {shouldShowError ? (
            <CloseButton
              trackedAdditionalData={{
                ...(retailerCheckoutOrderStatus || {}),
              }}
              onClick={handleError}
            >
              Close
            </CloseButton>
          ) : null}
          {shouldSuccess ? (
            <CloseButton onClick={handleSuccess}>
              {hasNextRetailerToCheckout ? "Checkout Next" : "View Summary"}
            </CloseButton>
          ) : null}
        </div>
      ) : (
        <div className="container">
          <Loading dot />
        </div>
      )}
    </StyledDialog>
  );
};

CheckoutTransition.toOrder = (orderId: string) => {
  useConfirmDialogStore.setState({
    orderId: orderId,
  });
};

export default CheckoutTransition;
