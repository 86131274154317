import {
  useEffect,
  createContext,
  useContext,
  useState,
  ReactElement,
} from "react";
import { useCheckoutContext } from "./CheckoutMachine";
import { useSelector } from "@xstate/react";

interface OrderContextInterface {
  openPayment: boolean;
  setOpenPayment: (open: boolean) => void;
  openShipping: boolean;
  setOpenShipping: (open: boolean) => void;
  openDelivery: boolean;
  setOpenDelivery: (open: boolean) => void;
}

export const OrderContext = createContext({} as OrderContextInterface);

export const OrderProvider = ({ children }: { children: ReactElement }) => {
  const [openPayment, setOpenPayment] = useState(false);
  const [openShipping, setOpenShipping] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const { actorRef } = useCheckoutContext();
  const checkoutState = useSelector(
    actorRef,
    (snapshot) => snapshot.context.checkoutState
  );
  const userInputError = useSelector(
    actorRef,
    (snapshot) => snapshot.context.userInputError
  );
  const userInputUpdateType = useSelector(
    actorRef,
    (snapshot) => snapshot.context.userInputUpdateType
  );
  const hasFailedAttemptErrors = useSelector(
    actorRef,
    (snapshot) =>
      !!snapshot.context.retailerCheckoutOrderStatus
        ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type
  );

  const hasError = !!userInputError || hasFailedAttemptErrors;

  useEffect(() => {
    if (checkoutState === "CHECKOUT_SUMMARY" && !hasError) {
      setOpenPayment(false);
      setOpenShipping(false);
      setOpenDelivery(false);
    }
    // ensure we close whenever we perform any user input update
  }, [checkoutState, hasError, userInputUpdateType]);

  return (
    <OrderContext.Provider
      value={{
        openPayment,
        setOpenPayment,
        openShipping,
        setOpenShipping,
        openDelivery,
        setOpenDelivery,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => {
  return useContext(OrderContext);
};
