import { assign, createMachine, ErrorActorEvent, fromPromise } from "xstate";

import {
  createTestCheckout,
  CreateTestCheckoutResponse,
  saveRetailerCredentials,
} from "@/api/rest/checkoutApi";
import { createPaymentIntent } from "@/components/Stripe/api";
import { UserRetailerShoppingCart } from "../../types";

// Define navigate as a simple function type instead of using React Router's type
type NavigateFunction = (path: string) => void;

export interface CheckoutContext {
  shipping: any | null;
  payment: any | null;
  currentCart: UserRetailerShoppingCart | null;
  currentRetailer: any | null;
  clientSecret: string | null;
  checkoutResponse: CreateTestCheckoutResponse | null;
  error: string | null;
  openSections: {
    signIn: boolean;
    retailerLogin: boolean;
    shipping: boolean;
    payment: boolean;
  };
  isRetailerConnected: boolean;
  retailerCredentials: {
    userName: string;
    password: string;
    retailerId: number;
  } | null;
  userId: string | null;
  amountToCharge: number | null;
  paymentIntent: any | null;
  savingRetailerLogin: boolean;
  onSubmitCheckout: () => void;
  navigate?: NavigateFunction;
}

interface PaymentIntentResponse {
  client_secret: string;
}

export const checkoutUIMachine = createMachine(
  {
    id: "checkoutUI",
    initial: "initial",
    context: ({
      input,
    }: {
      input?: { context?: Partial<CheckoutContext> };
    }) => {
      return {
        shipping: null,
        payment: null,
        currentCart: null,
        currentRetailer: null,
        clientSecret: null,
        error: null,
        openSections: {
          signIn: true,
          retailerLogin: false,
          shipping: false,
          payment: false,
        },
        isRetailerConnected: false,
        userId: null,
        amountToCharge: null,
        paymentIntent: null,
        savingRetailerLogin: false,
        navigate: undefined,
        ...(input?.context || {}),
      } as CheckoutContext;
    },
    on: {
      SET_RETAILER_CONNECTION: [
        {
          target: ".saving_retailer_credentials",
          guard: "hasUserIdAndRetailerConnected",
          actions: ["updateRetailerConnection", "setSavingRetailerLoginTrue"],
        },
        {
          actions: ["updateRetailerConnection"],
        },
      ],
      SET_USER_ID: [
        {
          target: ".shipping",
          guard: "hasUserIdAndRetailerConnected",
          actions: ["setUserId", "setShippingSections"],
        },
        {
          actions: ["setUserId"],
          target: ".connect_retailer",
        },
      ],
      SET_AMOUNT: {
        actions: [
          assign({
            amountToCharge: ({ event }) => event.data,
          }),
        ],
      },
      SET_SHIPPING: {
        actions: [
          assign({
            shipping: ({ event }) => event.data,
          }),
        ],
      },
      SET_CURRENT_RETAILER: {
        actions: [
          assign({
            currentRetailer: ({ event }) => event.data,
          }),
        ],
      },
    },
    states: {
      initial: {
        entry: ["checkInitialState"],
        on: {
          NEXT: [
            {
              target: "connect_retailer",
              guard: "hasUserId",
            },
            {
              target: "shipping",
              guard: "isRetailerConnected",
            },
          ],
          EDIT: {
            target: "initial",
            actions: "toggleSection",
          },
        },
      },
      connect_retailer: {
        entry: [
          assign({
            openSections: () => ({
              signIn: false,
              retailerLogin: true,
              shipping: false,
              payment: false,
            }),
          }),
        ],
        on: {
          NEXT: {
            target: "shipping",
            guard: "isRetailerConnected",
          },
          BACK: "initial",
          EDIT: {
            target: "connect_retailer",
            actions: "toggleSection",
          },
        },
      },
      saving_retailer_credentials: {
        invoke: {
          src: "saveRetailerCredentials",
          input: ({ context, event }) => {
            // Since we might get here from multiple events, we need to handle the event properly
            return {
              context,
              event: {
                type: event.type,
                data:
                  event.type === "SET_RETAILER_CONNECTION"
                    ? event.data
                    : context.retailerCredentials,
              },
            };
          },
          onDone: {
            target: "shipping",
            actions: [
              ({ event }) => {},
              "setSavingRetailerLoginFalse",
              "setShippingSections",
            ],
          },
          onError: {
            target: "connect_retailer",
            actions: [
              ({ event }) => {},
              assign({
                error: ({ event }) =>
                  `Failed to save retailer credentials: ${event.error}`,
                savingRetailerLogin: () => false,
              }),
            ],
          },
        },
      },
      shipping: {
        entry: assign({
          openSections: () => ({
            signIn: false,
            retailerLogin: false,
            shipping: true,
            payment: false,
          }),
        }),
        on: {
          NEXT: "payment",
          BACK: "connect_retailer",
          EDIT: {
            target: "shipping",
            actions: "toggleSection",
          },
        },
      },
      payment: {
        invoke: {
          src: "createPaymentIntent",
          input: ({ context }) => ({ context }),
          onDone: {
            actions: assign({
              clientSecret: ({ event }) => event.output.client_secret,
            }),
          },
          onError: {
            actions: assign({
              error: ({ event }: { event: ErrorActorEvent }) => {
                console.error("Failed to create payment intent", event);
                return typeof event.error === "string"
                  ? event.error
                  : event.error instanceof Error
                  ? event.error.message
                  : "Failed to create payment intent";
              },
            }),
            target: "error",
          },
        },
        entry: assign({
          openSections: () => ({
            signIn: false,
            retailerLogin: false,
            shipping: false,
            payment: true,
          }),
        }),
        on: {
          BACK: "shipping",
          EDIT: {
            target: "payment",
            actions: "toggleSection",
          },
          SUBMIT_PAYMENT_INTENT: "processing",
        },
      },
      processing: {
        on: {
          PAYMENT_INTENT_SUCCESS: "payment_success",
          PAYMENT_INTENT_ERROR: "error",
        },
      },
      payment_success: {
        entry: assign({
          paymentIntent: ({ event }: { event: { paymentIntent: any } }) =>
            event.paymentIntent,
          openSections: () => ({
            signIn: false,
            retailerLogin: false,
            shipping: false,
            payment: false,
          }),
        }),
        on: {
          NEXT: "checking_out",
        },
      },
      checking_out: {
        invoke: {
          src: "createCheckout",
          input: ({ context }) => ({ context }),
          onDone: {
            actions: assign({
              checkoutResponse: ({ event }) => event.output,
            }),
            target: "success",
          },
          onError: {
            target: "error",
          },
        },
        entry: assign({
          openSections: () => ({
            signIn: false,
            retailerLogin: false,
            shipping: false,
            payment: false,
          }),
        }),
      },
      success: {
        type: "final",
        entry: [
          assign({
            openSections: () => ({
              signIn: false,
              retailerLogin: false,
              shipping: false,
              payment: false,
            }),
          }),
          "navigateToOrderConfirmation",
        ],
        on: {
          RESET: "initial",
        },
      },
      error: {
        on: {
          RETRY: "processing",
          RESET: "initial",
        },
      },
    },
  },
  {
    actors: {
      createPaymentIntent: fromPromise(
        async ({ input }: { input: { context: CheckoutContext } }) => {
          if (!input.context.amountToCharge) {
            console.error("Amount to charge is missing from context");
            throw new Error("Amount to charge is required");
          }
          // Convert to cents for Stripe
          const amount = input.context.amountToCharge * 100;
          const response = await createPaymentIntent(amount);
          if (!response.client_secret) {
            throw new Error("Failed to create payment intent");
          }
          return response as PaymentIntentResponse;
        }
      ),
      createCheckout: fromPromise(
        async ({ input }: { input: { context: CheckoutContext } }) => {
          try {
            const body = {
              retailerId: input.context.currentRetailer.retailerId,
              isTestOrder: true,
              userInfo: {
                address: {
                  firstName: input.context.shipping.firstName,
                  lastName: input.context.shipping.lastName,
                  address1: input.context.shipping.address1,
                  address2: input.context.shipping.address2,
                  city: input.context.shipping.city,
                  state: input.context.shipping.state,
                  zip: input.context.shipping.zip,
                },
                stripePaymentIntentId: input.context.paymentIntent.id,
              },
            };
            const data = await createTestCheckout(body);
            return data;
          } catch (error) {
            console.error("Error creating checkout", error);
            throw error;
          }
        }
      ),
      saveRetailerCredentials: fromPromise(
        async ({
          input,
        }: {
          input: { context: CheckoutContext; event: any };
        }) => {
          const isPreviouslyConnectedAccount =
            input?.event?.data?.isPreviouslyConnectedAccount;

          if (isPreviouslyConnectedAccount) {
            return { success: true };
          }

          if (
            !input?.context?.userId ||
            !input?.event?.data?.retailerId ||
            !input?.event?.data?.userName ||
            !input?.event?.data?.password
          ) {
            return { success: false };
          }

          try {
            await saveRetailerCredentials({
              userId: input.context.userId,
              retailerId: input.event.data.retailerId,
              credentials: {
                username: input.event.data.userName,
                password: input.event.data.password,
              },
            });
            return { success: true };
          } catch (error) {
            console.error("Error saving retailer credentials", error);
            return { success: false, error };
          }
        }
      ),
    },
    actions: {
      setSavingRetailerLoginTrue: assign({
        savingRetailerLogin: () => true,
      }),
      setSavingRetailerLoginFalse: assign({
        savingRetailerLogin: () => false,
      }),
      toggleSection: assign({
        openSections: ({ context, event }) => {
          if (event.type === "EDIT") {
            const section = event.section as keyof typeof context.openSections;
            const result = {
              signIn: false,
              retailerLogin: false,
              shipping: false,
              payment: false,
            };
            result[section] = !context.openSections[section];
            return result;
          }
          return context.openSections;
        },
      }),
      updateRetailerConnection: assign({
        isRetailerConnected: ({ context, event }) => {
          if (event.type === "SET_RETAILER_CONNECTION") {
            return event.data;
          }
          return context.isRetailerConnected;
        },
        retailerCredentials: ({ event }) => {
          if (event.type === "SET_RETAILER_CONNECTION") {
            return event.data;
          }
        },
        openSections: ({ context, event }) => {
          if (event.type === "SET_RETAILER_CONNECTION") {
            return {
              ...context.openSections,
              retailerLogin: !event.data,
            };
          }
          return context.openSections;
        },
      }),
      checkInitialState: assign({
        openSections: ({ context }) => ({
          ...context.openSections,
          signIn: !context.userId,
          retailerLogin: context.userId ? !context.isRetailerConnected : false,
        }),
      }),
      setShipping: assign({
        shipping: ({ context, event }) => {
          if (event.type === "SET_SHIPPING") {
            return event.data;
          }
          return context.shipping;
        },
      }),
      setPayment: assign({
        payment: ({ context, event }) => {
          if (event.type === "SET_PAYMENT") {
            return event.data;
          }
          return context.payment;
        },
      }),
      setCart: assign({
        currentCart: ({ context, event }) => {
          if (event.type === "SET_CART") {
            return event.data;
          }
          return context.currentCart;
        },
      }),
      setRetailer: assign({
        currentRetailer: ({ context, event }) => {
          if (event.type === "SET_RETAILER") {
            return event.data;
          }
          return context.currentRetailer;
        },
      }),
      setClientSecret: assign({
        clientSecret: ({ context, event }) => {
          if (event.type === "SET_CLIENT_SECRET") {
            return event.data;
          }
          return context.clientSecret;
        },
      }),
      setError: assign({
        error: ({ context, event }) => {
          if (event.type === "SET_ERROR") {
            return event.data;
          }
          return context.error;
        },
      }),
      setUserId: assign({
        userId: ({ context, event }) => {
          if (event.type === "SET_USER_ID") {
            return event.data;
          }
          return context.userId;
        },
      }),
      setShippingSections: assign({
        openSections: () => ({
          signIn: false,
          retailerLogin: false,
          shipping: true,
          payment: false,
        }),
      }),
      navigateToOrderConfirmation: ({
        context,
      }: {
        context: CheckoutContext;
      }) => {
        if (context.navigate) {
          // Navigate to the order confirmation page with or without orderId
          const orderId =
            context.checkoutResponse?.userRetailerCheckoutOrder?.orderId;
          const path = orderId
            ? `/checkoutSummary?orderId=${orderId}`
            : "/checkoutSummary";

          try {
            context.navigate(path);
          } catch (error) {
            console.error("Navigation error:", error);
          }
        } else {
          console.error("No navigate function available");
        }
      },
      setAmountToCharge: assign({
        amountToCharge: ({ context, event }) => {
          if (event.type === "SET_AMOUNT") {
            return event.data;
          }
          return context.amountToCharge;
        },
      }),
    },
    guards: {
      hasUserId: ({ context }) => context.userId !== null,
      isRetailerConnected: ({ context }) => context.isRetailerConnected,
      hasUserIdAndRetailerConnected: ({ context, event }) => {
        const isUserIdSetAndRetailerConnected =
          event.type === "SET_USER_ID" &&
          event.data &&
          context.isRetailerConnected;
        const isRetailerConnectionSetAndUserIdSet =
          event.type === "SET_RETAILER_CONNECTION" &&
          event.data &&
          context.userId !== null;

        const hasConnectedRetailer =
          event.type === "SET_RETAILER_CONNECTION" &&
          event.data &&
          event.data.userName &&
          event.data.password;

        const res = Boolean(
          isUserIdSetAndRetailerConnected ||
            isRetailerConnectionSetAndUserIdSet ||
            hasConnectedRetailer
        );

        return res;
      },
    },
  }
);
