import { useFetchAccount } from "@/components/pages/AppPages/Pages/Accounts/data";
import { useSyncContext } from "@/components/SyncProvider";
import { SyncStatus } from "@/types/misc";
import { useFastCheckoutCart } from "../FastCheckoutCartProvider";

export const useCheckoutRetailer = () => {
  const { loading, connectedRetailers } = useFetchAccount({});

  const { currentRetailer } = useFastCheckoutCart();
  const { syncStatus, syncStatusByRetailer } = useSyncContext();

  const retailerSyncStatus = syncStatusByRetailer.find(
    (s) => s.retailerId === currentRetailer?.retailerId
  );

  const isTerminalSyncStatus =
    retailerSyncStatus?.syncStatus === "completed" ||
    retailerSyncStatus?.syncStatus === "connected" ||
    retailerSyncStatus?.syncStatus === "timed_out" ||
    retailerSyncStatus?.syncStatus === "error";

  const connectedStates: SyncStatus[] = [
    "completed",
    "scanning",
    "processing_rewards",
  ];

  const isValidConnection = connectedRetailers.some(
    (retailer) => retailer.retailerId === currentRetailer?.retailerId
  );

  const isError = retailerSyncStatus?.syncStatus === "error";
  const isConnected =
    isValidConnection ||
    (syncStatus === "syncing" &&
      connectedStates.includes(retailerSyncStatus?.syncStatus!));

  return {
    loading,
    currentRetailer,
    isConnected,
    isError,
    retailerSyncStatus,
    isTerminalSyncStatus,
  };
};
