import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import { styled, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";

import { CloseButton, hexadecimal } from "@brandclub/common-ui";
import { useSelector } from "@xstate/react";
import cardValidator from "card-validator";
import { CardNumberVerification } from "card-validator/dist/card-number";
import { MOBILE_SCREEN_SIZE } from "../AppNavigation/constants";
import { getLogoUrlForCardIssuer } from "../pages/AppPages/Dashboard/Wallet/CardBrandIcon";
import {
  getCardMasking,
  NumberFormatCustom,
} from "../StoreComponents/NumberFormatCustom";
import { SolidButton } from "../StoreComponents/StoreButton";
import { useCheckoutContext } from "./CheckoutMachine";
import { UserInputUpdateType } from "./CheckoutMachine/types/CheckoutMachine";
import { CheckoutInput } from "./components/CheckoutInput";

const SlideUpTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContentContainer = styled("div")(({ theme }) => ({
  padding: "68px 100px",
  position: "relative",
  width: "100%",
  boxSizing: "border-box",
  maxWidth: 580,
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "40px 20px 50px 20px",
  },
  ".title": {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 7,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 18,
    },
  },
  ".text": {
    fontSize: 16,
    textAlign: "center",
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".actions": {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      flexDirection: "column",
      gap: 10,
      alignItems: "center",
    },
  },
}));

const SubmitVerification = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const [cardInfo, setCardInfo] =
    useState<CardNumberVerification["card"] | undefined>(undefined);
  const [cvv, setCvv] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const desktopView = useMediaQuery(`(min-width:${MOBILE_SCREEN_SIZE}px)`);
  const theme = useTheme();
  const numberFormatStr = getCardMasking(cardInfo);

  const { actorRef, send: checkoutSend } = useCheckoutContext();
  const paymentMethod = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.paymentMethod
  );
  const type = paymentMethod?.paymentConfirmationTypes?.[0]?.type;

  const placeOrder = () => {
    const value = type === "cardNumber" ? cardNumber : cvv;
    checkoutSend({
      type: "CHECKOUT_USER_INPUT_EVENT",
      inputEvent:
        UserInputUpdateType.UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT,
      data: {
        paymentConfirmation: value,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={false}
      sx={{
        zIndex: 1400,
        "& div.MuiDialog-paper": {
          margin: 0,
          color: theme.palette.primary.main,
        },

        // media queries
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          "& div.MuiDialog-paper": {
            width: "100vw",
            minWidth: "100vw",
            maxWidth: "100vw",
            maxHeight: "75vh",
            position: "absolute",
            bottom: 0,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          },
        },
      }}
      PaperProps={{
        sx: { zIndex: 1502, width: "100%", maxWidth: "580px" },
      }}
      TransitionComponent={desktopView ? Fade : SlideUpTransition}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            zIndex: 1501,
          },
        },
      }}
    >
      <ContentContainer>
        <CloseButton
          onClick={close}
          iconSize={16}
          sx={{
            position: "absolute",
            top: 20,
            right: 20,

            display: "block",
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              display: "none",
            },
          }}
        />
        <div className="title">Verify your payment method</div>
        <Box
          className="text"
          sx={{
            fontSize: 12,
            color: theme.palette.primary.main,
            marginBottom: "20px",
          }}
        >
          {type === "cardNumber"
            ? `Please enter the full card number for your payment method ending in ${paymentMethod?.cardNumberEnding}.`
            : `Please enter the security code for your payment method ending in  ${paymentMethod?.cardNumberEnding}.`}
        </Box>
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <Box sx={{}}>
            {type === "cardNumber" ? (
              <CheckoutInput
                label={"Card Number"}
                value={cardNumber}
                onChange={(e) => {
                  const cardInfo = cardValidator.number(e.target.value);
                  setCardInfo(cardInfo?.card);
                  setCardNumber(e.target.value);
                }}
                inputProps={{
                  format: numberFormatStr ?? "#### #### #### ######",
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  startAdornment: cardInfo?.type ? (
                    <img
                      alt="logo"
                      src={getLogoUrlForCardIssuer(cardInfo?.type as any)}
                      style={{
                        width: 22,
                        height: 22,
                        objectFit: "contain",
                        marginTop: 18,
                        marginRight: 0,
                        marginLeft: 0,
                      }}
                    ></img>
                  ) : null,
                }}
              />
            ) : (
              <CheckoutInput
                label={"Card Number"}
                disabled
                value={"**** **** **** " + paymentMethod?.cardNumberEnding}
                sx={(theme) => ({
                  ".MuiInputBase-root": {
                    ".Mui-disabled": {
                      opacity: `1`,
                      color: `${theme.palette.primary.main} !important`,
                      WebkitTextFillColor: `${theme.palette.primary.main} !important`,
                    },
                  },
                })}
                InputProps={{
                  startAdornment: paymentMethod?.cardIssuer ? (
                    <img
                      alt="logo"
                      src={getLogoUrlForCardIssuer(
                        paymentMethod?.cardIssuer?.toLowerCase() as any
                      )}
                      style={{
                        width: 22,
                        height: 22,
                        objectFit: "contain",
                        marginTop: 18,
                        marginRight: 0,
                        marginLeft: 0,
                      }}
                    ></img>
                  ) : null,
                }}
              />
            )}
          </Box>
          {type === "cvv" ? (
            <Box>
              <CheckoutInput
                label={"Security code"}
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </Box>
          ) : null}
        </Box>
        <Box className="actions">
          <SolidButton
            disabled={!cardNumber && !cvv}
            sx={{
              fontSize: 16,
              width: "100%",
              maxWidth: "100%",
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                width: "100%",
                maxWidth: "100%",
                fontSize: 14,
              },
            }}
            onClick={() => {
              placeOrder();
              close();
            }}
          >
            Verify
          </SolidButton>
        </Box>
      </ContentContainer>
    </Dialog>
  );
};

export default SubmitVerification;
