import { assign, createMachine } from "xstate";
import {
  FastCheckoutMachineContext,
  FastCheckoutMachineEvents,
  FastCheckoutState,
} from "./types/FastCheckoutMachine";

export type FastCheckoutMachineType = typeof fastCheckoutMachine;

export const fastCheckoutMachine = (() => {
  const machineId = "fastCheckoutMachine";
  return createMachine(
    {
      id: machineId,
      initial: "waiting",
      types: {} as unknown as {
        context: FastCheckoutMachineContext;
        events: FastCheckoutMachineEvents;
      },
      on: {
        ON_SESSION_OPEN: {
          actions: ["openSection"],
        },
        ON_SESSION_CLOSE: {
          actions: ["closeSection"],
        },
        ON_CHECKOUT_STATUS_CHANGE: [
          {
            actions: [
              assign({
                checkoutState: FastCheckoutState.NON_ACTION_REQUIRED_ERROR,
              }),
            ],
            target: "#fastCheckoutMachine.receiptGenerated",
            guard: ({ context, event }) => {
              return !event?.data?.retailerCheckoutOrderStatus
                ?.userRetailerCheckoutOrder?.orderId;
            },
          },
          {
            actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
            target: "#fastCheckoutMachine.processing",
            guard: "isCheckoutProcessingStage",
          },
          {
            actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
            target: "#fastCheckoutMachine.actionRequiredError",
            guard: "isCheckoutActionRequiredErrorStage",
          },
          {
            actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
            target: "#fastCheckoutMachine.nonActionRequiredError",
            guard: "isCheckoutNonActionRequiredErrorStage",
          },
          {
            actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
            target: "#fastCheckoutMachine.receiptGenerated",
            guard: "isCheckoutReceiptGeneratedStage",
          },
        ],
      },
      context: ({
        input: { context },
      }: {
        input: { context: FastCheckoutMachineContext };
      }) => context,
      states: {
        waiting: {
          entry: [
            assign({
              checkoutState: FastCheckoutState.LOADING,
            }),
          ],
        },
        processing: {
          entry: [
            assign({
              checkoutState: FastCheckoutState.PROCESSING,
            }),
          ],
        },
        actionRequiredError: {
          entry: assign({
            checkoutState: FastCheckoutState.ACTION_REQUIRED_ERROR,
          }),
          on: {
            SUBMIT_LOGIN_INFO: {
              target: "processing",
            },
            SUBMIT_OTP: {
              target: "processing",
            },
            RESEND_OTP: {
              target: "processing",
            },
            OTP_TIME_OUT_RETRY: {
              target: "processing",
            },
            UPDATE_SHIPPING_ADDRESS: {
              target: "processing",
            },
            CONFIRM_PARTIALLY_OUT_OF_STOCK: {},
          },
        },
        nonActionRequiredError: {
          entry: assign({
            checkoutState: FastCheckoutState.NON_ACTION_REQUIRED_ERROR,
          }),
        },
        receiptGenerated: {
          entry: assign({
            checkoutState: FastCheckoutState.RECEIPT_GENERATED,
          }),
        },
      },
    },
    {
      actors: {},
      actions: {
        openSection: assign({
          sectionState: ({ event, context }) =>
            event?.type === "ON_SESSION_OPEN"
              ? {
                  ...context.sectionState,
                  [event.data.sessionToOpen]: {
                    ...context.sectionState[event.data.sessionToOpen],
                    open: true,
                  },
                }
              : context.sectionState,
        }),
        closeSection: assign({
          sectionState: ({ event, context }) =>
            event?.type === "ON_SESSION_CLOSE"
              ? {
                  ...context.sectionState,
                  [event.data.sessionToClose]: {
                    ...context.sectionState[event.data.sessionToClose],
                    open: false,
                  },
                }
              : context.sectionState,
        }),
        updateRetailerCheckoutOrderStatusFromPoller: assign({
          retailerCheckoutOrderStatus: ({ event, context }) =>
            event.type === "ON_CHECKOUT_STATUS_CHANGE"
              ? event.data.retailerCheckoutOrderStatus
              : context.retailerCheckoutOrderStatus,
        }),
        handleStatusChangeError: assign({
          error: ({ event, context }) => {
            if (event.type !== "ON_CHECKOUT_STATUS_CHANGE") {
              throw new Error(
                `Invalid event type. Expected "ON_CHECKOUT_STATUS_CHANGE", got ${event.type}`
              );
            }
            if (
              event.data.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                ?.status === "checkout_error"
            ) {
              return {
                message:
                  event.data.retailerCheckoutOrderStatus
                    ?.userRetailerCheckoutOrder.errorMessage ??
                  "An unknown error occurred",
                name: "CheckoutError",
              };
            }

            return context.error;
          },
        }),
        updateCheckoutRetailerConnectionState: assign(({ context, event }) => {
          const retailerCheckoutOrderStatus =
            event.type === "ON_CHECKOUT_STATUS_CHANGE"
              ? event.data.retailerCheckoutOrderStatus
              : context.retailerCheckoutOrderStatus;
          const lastFailedAttempt =
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.lastFailedAttempt;
          if (lastFailedAttempt?.type === "login") {
            return {
              checkoutRetailerConnectionState: {
                retailerId:
                  retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                    ?.retailerId,
                type: "RETRY_LOGIN",
                errorMessage: "please try again",
              } as FastCheckoutMachineContext["checkoutRetailerConnectionState"],
            };
          }
          return { checkoutRetailerConnectionState: undefined };
        }),
      },
      guards: {
        isCheckoutProcessingStage: ({ context, event }) => {
          return true;
        },
        isCheckoutActionRequiredErrorStage: ({ context, event }) => {
          return false;
        },
        isCheckoutNonActionRequiredErrorStage: ({ context, event }) => {
          return false;
        },
        isCheckoutReceiptGeneratedStage: ({ context, event }) => {
          return false;
        },
      },
    }
  );
})();
