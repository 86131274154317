import { useEffect, useState } from "react";
import { ShippingAddress } from "../../types";
import { CheckoutInput } from "../CheckoutInput";
import { InputContainer } from "../InputContainer";
import { styled } from "@mui/material";
import {
  OutlineButton,
  SolidButton,
} from "../../../StoreComponents/StoreButton";
import StoreAddressAutocompleteInput, {
  autocompleteAddressType,
} from "../../../StoreComponents/StoreAddressAutocompleteInput";
import { useAppSelector } from "../../../../redux/hooks";
import StoreRadio from "../../../StoreComponents/StoreRadio";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import { StoreError } from "../../../StoreComponents/StoreError";
import { getFullAddressWithNames } from "@/utils/StringUtils";
import { AddCircleOutlineIcon } from "@brandclub/common-ui";

const Container = styled("div")(({ theme }) => ({
  marginBottom: 20,
  ".existing": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gap: 6,
    },

    ".info_row": {
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-start",
      fontSize: 14,
      fontWeight: 500,
      ".icon": {
        width: 30,
        height: 30,
        objectFit: "contain",
        marginRight: 10,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          width: 20,
          height: 20,

          marginRight: 8,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          width: 20,
          height: 20,

          marginRight: 8,
        },
      },
      ".add_new_icon": {
        lineHeight: 0,
        marginRight: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 22,
        [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
          fontSize: 17,
        },
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 17,
        },
      },
    },
  },
  ".add_new": {
    marginTop: 44,
    ".title": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  ".actions": {
    marginTop: 30,
    display: "flex",
    gap: 20,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const CancelButton = styled(OutlineButton)({
  fontSize: 14,
  height: 37,
});

const SaveButton = styled(SolidButton)({
  fontSize: 14,
  height: 37,
});

interface CheckoutEditShippingFormProps {
  cancelEditing: () => void;
  availableAddresses?: ShippingAddress[];
  existingAddress?: ShippingAddress;
  handleUpdateShippingAddress: (
    method: "add" | "update",
    address: Partial<ShippingAddress>
  ) => void;
  error?: string;
}

const CheckoutEditShippingForm = ({
  cancelEditing,
  existingAddress,
  handleUpdateShippingAddress,
  availableAddresses,
  error,
}: CheckoutEditShippingFormProps) => {
  const addresses = availableAddresses;
  const phoneNumberFromProfile = useAppSelector(
    (state) => state.userProfile?.phoneNumber
  );
  const [address1, setAddress1] = useState(existingAddress?.address1 || "");
  const [address2, setAddress2] = useState(existingAddress?.address2 || "");
  const [city, setCity] = useState(existingAddress?.city || "");
  const [state, setState] = useState(existingAddress?.state || "");
  const [zipCode, setZipCode] = useState(existingAddress?.zipCode || "");
  const [firstName, setFirstName] = useState(existingAddress?.firstName || "");
  const [lastName, setLastName] = useState(existingAddress?.lastName || "");
  const [phoneNumber, setPhoneNumber] = useState(
    existingAddress?.phoneNumber || ""
  );

  const [addNewOpen, setAddNewOpen] = useState(false);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(-1);

  const selectedAddress = addresses?.[selectedAddressIndex];

  useEffect(() => {
    if (!addresses?.length) {
      setAddNewOpen(true);
    }
  }, [addresses?.length]);

  const canSubmit =
    selectedAddressIndex >= 0 ||
    (address1 &&
      city &&
      state &&
      zipCode &&
      firstName &&
      lastName &&
      phoneNumber);

  return (
    <Container>
      <StoreError errorMessage={error} sx={{ marginBottom: 2 }} />
      {addresses?.length ? (
        <div className="existing">
          {addresses.map((address, index) => {
            const addressDisplay = getFullAddressWithNames(address);
            const selected =
              selectedAddressIndex > 0
                ? selectedAddressIndex === index
                : address?.extendedAttributes?.isSelected;
            return (
              <div
                className="info_row"
                key={address.id}
                onClick={() => {
                  setSelectedAddressIndex(index);
                  setAddNewOpen(false);
                }}
                style={{ fontWeight: selected ? 600 : 500 }}
              >
                <StoreRadio
                  key={address.id}
                  style={{ marginRight: 10 }}
                  checked={!!selected}
                  disableRipple
                  onClick={() => {}}
                />
                {addressDisplay}
              </div>
            );
          })}
          <div
            className="info_row"
            onClick={() => {
              setSelectedAddressIndex(-1);
              setAddNewOpen(true);
            }}
          >
            <AddCircleOutlineIcon className="add_new_icon" />
            Add new shipping address
          </div>
        </div>
      ) : null}
      {addNewOpen ? (
        <div className="add_new">
          <div className="title">Add Shipping Address</div>
          <InputContainer>
            <CheckoutInput
              label={"First Name"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <CheckoutInput
              label={"Last Name"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <StoreAddressAutocompleteInput
              searchTerm={address1}
              selectAddressCallback={(address: autocompleteAddressType) => {
                setAddress1(address?.line1 || "");
                setCity(address?.city || "");
                setState(address?.state || "");
                setZipCode(address?.zipCode || "");
              }}
              renderInput={(param: any) => (
                <CheckoutInput
                  placeholder="Begin typing to search"
                  label={"Street address"}
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  {...param}
                  InputProps={{
                    ...param.InputProps,
                  }}
                  inputProps={{
                    ...param.inputProps,
                    value: address1,
                  }}
                />
              )}
            />
            <CheckoutInput
              label={"Suite, Apartment, C/O"}
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <CheckoutInput
              label={"City"}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <CheckoutInput
              label={"State"}
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <CheckoutInput
              label={"Zip Code"}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <CheckoutInput
              label={"Phone Number"}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </InputContainer>
        </div>
      ) : null}

      <div className="actions">
        <CancelButton onClick={cancelEditing}>Cancel</CancelButton>
        <SaveButton
          disabled={!canSubmit}
          onClick={() => {
            if (selectedAddress) {
              handleUpdateShippingAddress("update", {
                address1: selectedAddress.address1,
                address2: selectedAddress.address2,
                city: selectedAddress.city,
                state: selectedAddress.state,
                zipCode: selectedAddress.zipCode,
                firstName: selectedAddress.firstName,
                lastName: selectedAddress.lastName,
                phoneNumber: phoneNumberFromProfile as string,
              });
            } else {
              handleUpdateShippingAddress("add", {
                address1,
                address2,
                city,
                state,
                zipCode,
                firstName,
                lastName,
                phoneNumber,
              });
            }
          }}
        >
          Save
        </SaveButton>
      </div>
    </Container>
  );
};

export default CheckoutEditShippingForm;
