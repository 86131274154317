import { RetailerCheckoutOrderStatus } from "@/api/rest/checkoutApi";
import { ProductCardProps } from "@/components/Checkout/components/CheckoutFlow/EnterCheckoutAnimation";
import { AppDispatch } from "@/redux/store";
import { MfaValues } from "@/types/misc";
import { NavigateFunction } from "react-router-dom";
import { RetailerConnectionState } from "../../../../types/misc";
import {
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutOrder,
  UserRetailerShoppingCart,
} from "../../types";

type RetailerConnectionInfoList = {
  retailerId: number;
  credential: {
    username?: string;
    password?: string;
    connectionStatus: RetailerConnectionState;
  };
}[];

export enum FastCheckoutState {
  LOADING = "LOADING",
  PROCESSING = "PROCESSING",
  ACTION_REQUIRED_ERROR = "ACTION_REQUIRED_ERROR",
  NON_ACTION_REQUIRED_ERROR = "NON_ACTION_REQUIRED_ERROR",
  RECEIPT_GENERATED = "RECEIPT_GENERATED",
}

export const fastCheckoutDisplayData: Record<string, string> = {
  [FastCheckoutState.PROCESSING]: "Processing your order",
  [FastCheckoutState.RECEIPT_GENERATED]: "Order details",
};

export enum FastCheckoutError {
  LOGIN_ERROR = "LOGIN_ERROR",
  OTP_ERROR = "OTP_ERROR", // do polling, only exception for polling
  OTP_TIMEOUT_ERROR = "OTP_TIMEOUT_ERROR",
  PAYMENT_ERROR = "PAYMENT_ERROR", // non-action required
  SHIPPING_ERROR = "SHIPPING_ERROR",
  DELIVERY_ALL_OUT_OF_STOCK_ERROR = "DELIVERY_ALL_OUT_OF_STOCK_ERROR", // non-action required
  DELIVERY_PARTIALLY_OUT_OF_STOCK_ERROR = "DELIVERY_PARTIALLY_OUT_OF_STOCK_ERROR",
  DELIVERY_FEES_TOO_HIGH_ERROR = "DELIVERY_FEES_TOO_HIGH_ERROR",
}

export enum FastUserInputUpdateType {
  UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT = "UPDATE_PAYMENT_CONFIRMATION_AND_COMPLETE_CHECKOUT",
  UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD",
  UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS",
  UPDATE_SHIPPING_OPTION = "UPDATE_SHIPPING_OPTION",
  ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS",
  ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
  REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM = "REMOVE_PARTIALLY_OUT_OF_STOCK_ITEM",
}

type SessionOpenState = {
  open?: boolean;
  chevronShow?: boolean;
  errorMessage?: string;
};

export type FastCheckoutMachineContext = {
  sectionState: {
    account: SessionOpenState;
    payment: SessionOpenState;
    shipping: SessionOpenState;
    delivery: SessionOpenState;
  };
  checkoutState?: FastCheckoutState;
  retailerIds?: number[];
  retailerConnectionInfoList?: RetailerConnectionInfoList;
  activeCheckoutRetailerId?: number;
  checkoutBatch?: UserRetailerCheckoutBatch;
  activeCheckoutOrder?: UserRetailerCheckoutOrder;
  retailerCheckoutOrderStatus?: RetailerCheckoutOrderStatus;
  checkoutAllCarts?: Partial<UserRetailerShoppingCart>[];
  error?: Error;
  userInputError?: Error;
  openState?: "login" | "payment" | "shipping" | "delivery";
  enterAnimationConfig?: {
    title: string;
    checkoutCards: ProductCardProps[];
  };
  otpStatus?: MfaValues;
  checkoutRetailerConnectionState?: {
    retailerId: number;
    type: "INITIAL_CONNECT" | "RETRY_LOGIN";
    errorMessage?: string;
  };
  navigate: NavigateFunction;
  dispatch: AppDispatch;
  userInputUpdateType?: FastUserInputUpdateType;
  animationCompleted?: boolean;
  /**
   * Indicates if the user had to enter login info for a retailer.
   */
  hasUserLoggedInRetailer?: boolean;
};

export type FastCheckoutStatusChangeEvent = {
  type: "ON_CHECKOUT_STATUS_CHANGE";
  data: {
    retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
  };
};

export type FastCheckoutSectionOpenEvent = {
  type: "ON_SESSION_OPEN";
  data: {
    sessionToOpen: "account" | "payment" | "shipping" | "delivery";
  };
};

export type FastCheckoutSectionCloseEvent = {
  type: "ON_SESSION_CLOSE";
  data: {
    sessionToClose: "account" | "payment" | "shipping" | "delivery";
  };
};

export type FastCheckoutSubmitLoginInfoEvent = {
  type: "SUBMIT_LOGIN_INFO";
  data: {
    username?: string;
    password?: string;
  };
};

export type FastCheckoutSubmitOtpEvent = {
  type: "SUBMIT_OTP";
  data: {
    otp?: string;
  };
};

export type FastCheckoutResendOtpEvent = {
  type: "RESEND_OTP";
};

export type FastCheckoutOtpTimeoutRetryEvent = {
  type: "OTP_TIME_OUT_RETRY";
};

export type FastCheckoutUpdateShippingAddressEvent = {
  type: "UPDATE_SHIPPING_ADDRESS";
};

export type FastCheckoutConfirmPartiallyOutOfStockEvent = {
  type: "CONFIRM_PARTIALLY_OUT_OF_STOCK";
};

export type FastCheckoutMachineEvents =
  | FastCheckoutStatusChangeEvent
  | FastCheckoutSectionOpenEvent
  | FastCheckoutSectionCloseEvent
  | FastCheckoutSubmitLoginInfoEvent
  | FastCheckoutSubmitOtpEvent
  | FastCheckoutResendOtpEvent
  | FastCheckoutOtpTimeoutRetryEvent
  | FastCheckoutUpdateShippingAddressEvent
  | FastCheckoutConfirmPartiallyOutOfStockEvent;
