import { UserLoginContext } from "@/components/pages/Auth/UserLoginProvider";
import { reloadProfile } from "@/redux/actions";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { formatPhone } from "@/utils/StringUtils";
import React, { useContext, useState } from "react";

const EnterPhoneNumber = ({
  onSubmit,
}: {
  onSubmit: (phoneNumber: string) => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const phoneNumber = formData.get("phoneNumber") as string;
    onSubmit(phoneNumber);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Phone Number" name="phoneNumber" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

const EnterOTP = ({
  onSubmit,
  resendOTP,
  resendCooldown,
}: {
  onSubmit: (otp: string) => void;
  resendOTP: () => void;
  resendCooldown: number;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const otp = formData.get("otp") as string;
    onSubmit(otp);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Enter OTP" name="otp" />
        <button type="submit">Submit</button>
      </form>
      <button onClick={resendOTP}>Resend OTP</button>
      {resendCooldown > 0 && <p>Resend cooldown: {resendCooldown} seconds</p>}
    </div>
  );
};

const SignIn = () => {
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector((state) => state.userProfile);

  const [step, setStep] = useState<"phone" | "otp">("phone");
  const [otpSession, setOtpSession] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const {
    signIn,
    submittingSignIn,
    submittingOtp,
    signInError,
    otpError,
    resendCooldown,
    verifyOtp,
    resendOtp,
  } = useContext(UserLoginContext);

  const submitPhoneNumber = (phone: string) => {
    const phoneNumberInput = formatPhone(phone, "e164");
    setPhoneNumber(phoneNumberInput); // set for otp resend use
    signIn(phoneNumberInput, false, (_, session) => {
      setStep("otp");
      setOtpSession(session);
    });
  };

  const submitOTP = (otp: string) => {
    verifyOtp(otp, otpSession, () => {
      dispatch(reloadProfile());
    });
  };

  const resendOTP = () => {
    resendOtp(phoneNumber, false, (session) => {
      setOtpSession(session);
    });
  };

  if (submittingSignIn || submittingOtp) {
    return <div>Submitting...</div>;
  }

  if (userProfile) {
    return (
      <div>
        <div>{formatPhone(userProfile.phoneNumber!)}</div>
        <div>{userProfile.email}</div>
      </div>
    );
  }

  return (
    <div>
      {step === "phone" ? (
        <EnterPhoneNumber onSubmit={submitPhoneNumber} />
      ) : (
        <EnterOTP
          onSubmit={submitOTP}
          resendOTP={resendOTP}
          resendCooldown={resendCooldown}
        />
      )}
      {signInError || otpError ? (
        <div>
          <p>{signInError || otpError}</p>
        </div>
      ) : null}
    </div>
  );
};

export default SignIn;
