import { Box } from "@mui/material";

import { ShippingAddress } from "../../../types";

import CheckoutEditShipingForm from "../CheckoutEditShipingForm";
import { getSortedAddresses } from "../../../utils";
import { useFastCheckoutContext } from "../../../CheckoutMachine";
import { ChevronDownIcon } from "@brandclub/common-ui";
import { StoreAnimatedHeightDiv } from "../../../../StoreComponents/StoreAnimatedHeightDiv";
import { AnimatePresence, motion } from "framer-motion";
import { buildAddressString } from "../../../utils";
import InfoBox from "../InfoBox";
import { useState } from "react";
import { ERROR_MESSAGES } from "../../../../../utils/errors/errorUtils";

import { useSelector } from "@xstate/react";

interface SelectedAddressProps {
  shippingAddress?: Partial<ShippingAddress>;
  onAddShippingAddress: () => void;
}
const SelectedAddress = (props: SelectedAddressProps) => {
  const { shippingAddress } = props;
  if (!shippingAddress) {
    return (
      <InfoBox
        message="Please add a shipping address to complete this purchase."
        cta="Add new shipping address"
        onClick={props.onAddShippingAddress}
      />
    );
  }
  return (
    <div className="info_box">
      <div className="info_row">
        {shippingAddress?.firstName} {shippingAddress?.lastName}
      </div>

      <div className="info_row address">
        {buildAddressString(shippingAddress)}
      </div>
    </div>
  );
};

const ShippingSection = () => {
  const { actorRef, send } = useFastCheckoutContext();

  const open = useSelector(
    actorRef,
    (state) => state.context.sectionState.shipping.open
  );
  const chevronShow = useSelector(
    actorRef,
    (state) => state.context.sectionState.shipping.chevronShow
  );

  const toggleOpen = () => {
    if (open) {
      send({ type: "ON_SESSION_CLOSE", data: { sessionToClose: "shipping" } });
    } else {
      send({ type: "ON_SESSION_OPEN", data: { sessionToOpen: "shipping" } });
    }
  };

  const shippingAddress = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.shippingAddress
  );
  const availableAddresses = useSelector(
    actorRef,
    (snapshot) =>
      snapshot.context.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
        ?.availableAddresses
  );

  const [error, setError] = useState("");

  const sortedShippingAddresses = getSortedAddresses(availableAddresses);

  const handleUpdateShippingAddress = async (
    method: "add" | "update",
    address: Partial<ShippingAddress>
  ) => {
    try {
      setError("");
      console.log("todo: handleUpdateShippingAddress", address, method);
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : ERROR_MESSAGES.default[0];
      setError(errorMessage);
    }
  };

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => {}}
      >
        <div className="title">{"Shipping Address"}</div>
        {chevronShow ? (
          <ChevronDownIcon
            style={{
              transition: "transform 0.3s",
              width: "33px",
              height: "33px",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={toggleOpen}
          ></ChevronDownIcon>
        ) : null}
      </div>

      <StoreAnimatedHeightDiv>
        <AnimatePresence mode="wait">
          <motion.div
            layout="position"
            key={open ? "view" : "edit"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {open ? (
              <CheckoutEditShipingForm
                availableAddresses={sortedShippingAddresses}
                handleUpdateShippingAddress={handleUpdateShippingAddress}
                existingAddress={shippingAddress}
                error={error}
                cancelEditing={() => {
                  send({
                    type: "ON_SESSION_CLOSE",
                    data: { sessionToClose: "shipping" },
                  });
                }}
              />
            ) : (
              <SelectedAddress
                shippingAddress={shippingAddress}
                onAddShippingAddress={() => {
                  send({
                    type: "ON_SESSION_OPEN",
                    data: { sessionToOpen: "shipping" },
                  });
                }}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </StoreAnimatedHeightDiv>
    </Box>
  );
};

export default ShippingSection;
