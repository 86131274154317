import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { MOBILE_SCREEN_SIZE, TABLET_SCREEN_SIZE } from "@brandclub/common-ui";

const SveContainer = styled("svg")(({ theme }) => ({
  width: 18,
  height: 18,
  transition: "fill 0.3s ease-in-out",
  fill: theme.palette.primary.main,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: 12,
    height: 12,
  },

  "&.active": {
    fill: theme.palette.primary.contrastText,
  },
}));

const IconStore = ({ active }: { active: boolean }) => {
  return (
    <SveContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.817 12.106"
      className={active ? "active" : ""}
    >
      <g data-name="Group 21928">
        <g data-name="Group 21798">
          <g data-name="Group 21797">
            <path
              data-name="Path 25696"
              d="M14.243 236.122a2.8 2.8 0 0 1-4.289 0 2.822 2.822 0 0 1-3.762.5v5.58a.443.443 0 0 0 .443.443H9.2v-3.325a.443.443 0 0 1 .443-.443h4.908a.443.443 0 0 1 .443.443v3.325h2.567a.443.443 0 0 0 .443-.443v-5.58a2.822 2.822 0 0 1-3.761-.5z"
              transform="translate(-6.189 -230.539)"
            />
          </g>
        </g>
        <g data-name="Group 21800">
          <g data-name="Group 21799">
            <path
              data-name="Path 25697"
              d="M17.451.394A.443.443 0 0 0 17.01 0h-10a.443.443 0 0 0-.44.394c-.528 4.742-.47 4.119-.47 4.349.6 1.489 3.319 1.134 3.319-.425a.443.443 0 1 1 .886 0 1.568 1.568 0 0 0 1.7 1.378 1.568 1.568 0 0 0 1.7-1.378.443.443 0 0 1 .886 0c0 1.563 2.723 1.91 3.319.425.006-.243.062.349-.459-4.349z"
              transform="translate(-6.101)"
            />
          </g>
        </g>
      </g>
    </SveContainer>
  );
};

const IconWallet = ({ active }: { active: boolean }) => {
  return (
    <SveContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.119 12.119"
      className={active ? "active" : ""}
    >
      <path
        data-name="Path 25698"
        d="M60.994 1.84A1.185 1.185 0 0 1 62.177.657h.41l-.124-.344a.473.473 0 0 0-.607-.285l-4.139 1.507a.473.473 0 0 0-.283.608l.219.6h3.34z"
        transform="translate(-56.047)"
      />
      <path
        data-name="Path 25699"
        d="M239.473 57.745a.473.473 0 0 0-.473.473v.9h4.142v-.9a.473.473 0 0 0-.473-.473z"
        transform="translate(-233.343 -56.378)"
      />
      <path
        data-name="Path 25700"
        d="M11.054 151.863H9.4a1.775 1.775 0 1 1 0-3.55h1.657v-1.621a.947.947 0 0 0-.947-.947H.947a.947.947 0 0 0-.947.947v6.775a.947.947 0 0 0 .947.947h9.16a.947.947 0 0 0 .947-.947z"
        transform="translate(0 -142.295)"
      />
      <path
        data-name="Path 25701"
        d="M355.432 284.245h-2.367a1.065 1.065 0 0 0 0 2.13h2.367a.355.355 0 0 0 .355-.355v-1.42a.355.355 0 0 0-.355-.355zm-2.367 1.42a.355.355 0 1 1 .355-.355.355.355 0 0 1-.355.355z"
        transform="translate(-343.668 -277.517)"
      />
    </SveContainer>
  );
};

const IconShipping = ({ active }: { active: boolean }) => {
  return (
    <SveContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.35 10.319"
      className={active ? "active" : ""}
    >
      <g data-name="delivery-truck (3)">
        <g data-name="Group 21802">
          <g data-name="Group 21801">
            <path
              data-name="Path 25703"
              d="M64.823 311.112a1.665 1.665 0 1 1-1.665 1.665 1.667 1.667 0 0 1 1.665-1.665z"
              transform="translate(-55.662 -304.123)"
            />
          </g>
        </g>
        <g data-name="Group 21804">
          <g data-name="Group 21803">
            <path
              data-name="Path 25704"
              d="M312.265 311.112a1.665 1.665 0 1 1-1.665 1.665 1.667 1.667 0 0 1 1.665-1.665z"
              transform="translate(-309.073 -304.123)"
            />
          </g>
        </g>
        <g data-name="Group 21806">
          <g data-name="Group 21805">
            <path
              data-name="Path 25705"
              d="M1.32 137.63a1.509 1.509 0 0 1 1.507 1.507v4.831a.85.85 0 0 1-.849.849H1.93a2.329 2.329 0 0 0 .028-.362A2.323 2.323 0 0 0 0 142.164v-4.534h1.32z"
              transform="translate(9.523 -135.802)"
            />
          </g>
        </g>
        <g data-name="Group 21808">
          <g data-name="Group 21807">
            <path
              data-name="Path 25706"
              d="M148.153 61.812h6.875a.963.963 0 0 1 .962.962v5.4a2.321 2.321 0 0 0-1.93 2.653h-1.385a2.32 2.32 0 1 0-4.583 0 .963.963 0 0 1-.9-.96v-7.093a.963.963 0 0 1 .961-.962z"
              transform="translate(-147.191 -61.812)"
            />
          </g>
        </g>
      </g>
    </SveContainer>
  );
};

const IconBox = ({ active }: { active: boolean }) => {
  return (
    <SveContainer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.263 12.119"
      className={active ? "active" : ""}
    >
      <path
        data-name="Path 25724"
        d="M4.547 9.532a1.029 1.029 0 0 1-.374-.07L1.267 8.334v1.894a.359.359 0 0 0 .238.338l4.3 1.549V8.386l-.367.631a1.036 1.036 0 0 1-.891.515z"
      />
      <path
        data-name="Path 25725"
        d="M7.717 9.532a1.036 1.036 0 0 1-.893-.514l-.3-.514v3.615l4.312-1.553a.359.359 0 0 0 .238-.338V8.304L8.09 9.462a1.028 1.028 0 0 1-.373.07z"
      />
      <path
        data-name="Path 25726"
        d="M3.684 4.469a3.162 3.162 0 0 1-.51-.877l-.957.345z"
      />
      <path
        data-name="Path 25727"
        d="m10.048 3.934-.96-.352a3.161 3.161 0 0 1-.513.886z"
      />
      <path
        data-name="Path 25728"
        d="m12.218 6.697-1.192-2.081a.338.338 0 0 0-.409-.15L6.132 6.09 1.646 4.465a.338.338 0 0 0-.409.15L.049 6.69A.35.35 0 0 0 0 6.879a.339.339 0 0 0 .216.3l4.208 1.633a.338.338 0 0 0 .415-.145l1.293-2.313 1.293 2.313a.338.338 0 0 0 .415.145l4.208-1.633a.338.338 0 0 0 .171-.483z"
      />
      <path
        data-name="Path 25729"
        d="M6.132 4.931a2.466 2.466 0 1 0-2.466-2.466 2.469 2.469 0 0 0 2.466 2.466zm-1.02-2.088a.36.36 0 0 1 .508-.508l.2.2.834-.834a.36.36 0 1 1 .508.508l-1.086 1.09a.359.359 0 0 1-.508 0z"
      />
    </SveContainer>
  );
};

const BAR_HEIGHT_DESKTOP = 7;
const BAR_HEIGHT_MOBILE = 4;

const ICON_SIZE_DESKTOP = 34;
const ICON_SIZE_MOBILE = 24;

// Styled components for the progress tracker
const ProgressContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  margin: "0px auto",
  position: "relative",
}));

const ProgressBar = styled(Box)(({ theme }) => ({
  width: `calc(100% - ${ICON_SIZE_DESKTOP}px)`,
  height: BAR_HEIGHT_DESKTOP,
  backgroundColor: "#e8e8ed",
  position: "absolute",
  top: "50%",
  margin: `0 ${ICON_SIZE_DESKTOP / 2}px`,
  transform: `translateY(-50%)`,
  zIndex: 1,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: `calc(100% - ${ICON_SIZE_MOBILE}px)`,
    height: BAR_HEIGHT_MOBILE,
    margin: `0 ${ICON_SIZE_MOBILE / 2}px`,
  },
}));

// Define interface for the props
interface ProgressCompletedProps {
  progress: number;
}

const ProgressCompleted = styled(Box)<ProgressCompletedProps>(
  ({ theme, progress }) => ({
    position: "absolute",
    height: BAR_HEIGHT_DESKTOP,
    backgroundColor: theme.palette.primary.main,
    top: "50%",
    transform: `translateY(-50%)`,
    width: `${progress}%`,
    zIndex: 2,
    [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      height: BAR_HEIGHT_MOBILE,
    },
  })
);

// Animation for the progress pulse - revised to work correctly with each segment
const progressPulse = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(1) translateY(-50%);
    transform-origin: right;
  }
  50% {
    opacity: 1;
    transform: scaleX(1) translateY(-50%);
    transform-origin: right;
  }
  100% {
    opacity: 1;
    transform: scaleX(0) translateY(-50%);
    transform-origin: right;
  }
`;

// Define interface for animation props
interface ProgressAnimationProps {
  activeStep: number;
}

// Animated progress indicator - adjusted to support multiple active steps
const ProgressAnimation = styled(Box)<ProgressAnimationProps>(
  ({ theme, activeStep }) => {
    // Calculate the segment width - for example if on step 2 of 4, each segment is 33.3%
    const totalSteps = 4;
    const segmentWidth = 100 / (totalSteps - 1);

    // Position based on active step
    const startPosition = (activeStep - 1) * segmentWidth;
    // End position is where the next step would be
    const endPosition = Math.min(activeStep * segmentWidth, 100);
    // Width is just the difference between end and start
    const width = endPosition - startPosition;

    return {
      position: "absolute",
      height: BAR_HEIGHT_DESKTOP,
      top: "50%",
      transform: `translateY(-50%)`,
      left: `${startPosition}%`,
      width: `calc(${width}% - ${ICON_SIZE_DESKTOP / 2}px)`,
      zIndex: 2,
      backgroundColor: theme.palette.primary.main,
      animation: `${progressPulse} 3.5s ease-in infinite`,

      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        height: BAR_HEIGHT_MOBILE,
        width: `calc(${width}% - ${ICON_SIZE_MOBILE / 2}px)`,
      },
    };
  }
);

const ProgressSteps = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 3,
}));

// Define interface for Step props
interface StepProps {
  active: boolean;
}

const Step = styled(Box)<StepProps>(({ theme, active }) => ({
  width: ICON_SIZE_DESKTOP,
  height: ICON_SIZE_DESKTOP,
  borderRadius: "50%",
  backgroundColor: active ? theme.palette.primary.main : "#e8e8ed",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  transition: "all 0.3s ease-in-out",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    width: ICON_SIZE_MOBILE,
    height: ICON_SIZE_MOBILE,
  },
}));

// Define interface for component props
interface ProgressTrackerProps {
  activeStep?: number;
}

// Progress Tracker Component
const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  activeStep = 3,
}) => {
  // Calculate progress percentage
  const progressPercentage = ((activeStep - 1) / 3) * 100;

  // Show animation for any active step except the last one (4)
  const showAnimation = activeStep > 0 && activeStep < 4;

  return (
    <ProgressContainer>
      <ProgressBar />
      <ProgressCompleted progress={progressPercentage} />
      {showAnimation && <ProgressAnimation activeStep={activeStep} />}
      <ProgressSteps>
        <Step active={activeStep >= 1}>
          <IconStore active={activeStep >= 1} />
        </Step>
        <Step active={activeStep >= 2}>
          <IconWallet active={activeStep >= 2} />
        </Step>
        <Step active={activeStep >= 3}>
          <IconShipping active={activeStep >= 3} />
        </Step>
        <Step active={activeStep >= 4}>
          <IconBox active={activeStep >= 4} />
        </Step>
      </ProgressSteps>
    </ProgressContainer>
  );
};

export default ProgressTracker;
