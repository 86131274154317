import { getRetailerCheckoutOrderStatusV2 } from "@/api/rest/checkoutApi";
import { useSelector } from "@xstate/react";
import { useCallback, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useFastCheckoutContext } from "./CheckoutMachine";

const POLL_INTERVAL = 3e3; // 3 seconds

const ServerSideCheckoutStatusPollerV2 = () => {
  const { send, actorRef } = useFastCheckoutContext();

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");

  const checkoutState = useSelector(actorRef, (snapshot) => snapshot.value);
  const status = useSelector(actorRef, (snapshot) => snapshot.status);
  const aborterRef = useRef(new AbortController());
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isCheckoutStatusFinal =
    status === "done" ||
    checkoutState === "checkoutError" ||
    checkoutState === "checkoutSuccess";

  const fetchOrderForPoll = useCallback(
    async (orderId: string) => {
      aborterRef.current.abort();
      const controller = new AbortController();
      aborterRef.current = controller;
      try {
        const res = await getRetailerCheckoutOrderStatusV2(
          {
            orderId,
          },
          controller.signal
        );
        send({
          type: "ON_CHECKOUT_STATUS_CHANGE",
          data: {
            retailerCheckoutOrderStatus: res,
          },
        });
        return res;
      } catch (e) {
        console.error("polling error", e);
      }
    },
    [send]
  );
  useEffect(() => {
    if (!orderId || isCheckoutStatusFinal) {
      return;
    } else {
      fetchOrderForPoll(orderId);
      pollIntervalRef.current = setInterval(async () => {
        fetchOrderForPoll(orderId);
      }, POLL_INTERVAL);
    }

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [isCheckoutStatusFinal, orderId, fetchOrderForPoll]);

  return <> </>;
};

export default ServerSideCheckoutStatusPollerV2;
