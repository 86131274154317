import { EmptyBagMessageCard } from "@/components/Checkout/EmptyBagMessageCard";
import LandingPageWrapper from "@/components/pages/AppPages/Pages/components/LandingPageWrapper";
import { useAppSelector } from "@/redux/hooks";
import { styled } from "@mui/material";
import { useEffect } from "react";
import { CheckoutUIProvider, useCheckoutUI } from "../CheckoutUIProvider";
import {
  FastCheckoutCartProvider,
  useFastCheckoutCart,
} from "../FastCheckoutCartProvider";
import { useCheckoutRetailer } from "../hooks/useCheckoutRetailer";
import { CheckoutEditPaymentForm } from "./components/CheckoutEditPaymentForm";
import CollapsibleSection from "./components/CollapsibleSection";
import { CurrentCart } from "./components/CurrentCart";
import { RetailerLoginForm } from "./components/RetailerLoginForm";
import { ShippingMethodForm } from "./components/ShippingMethodForm";
import SignIn from "./SignIn";
import { CartTotal } from "./components/CartTotal";
import { SolidButton } from "@/components/StoreComponents/StoreButton";
import { CartProduct } from "../../CartProduct";
import DebugPanel from "./components/DebugPanel";
import { getEntityImage } from "@/utils/StringUtils";
import { Divider } from "../../Divider";

const StyledEmptyBagMessageCard = styled(EmptyBagMessageCard)({
  margin: "auto",
  maxHeight: 250,
  maxWidth: 500,
});

const CartItemsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const CartTitle = styled("div")({
  fontSize: "18px",
  fontWeight: 600,
  color: "#000",
});

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  marginTop: "60px",
  gap: "60px",
  ".content": {
    flex: 1,
    [theme.breakpoints.down("md")]: {
      flex: "none",
    },
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  ".sidebar": {
    width: "430px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  ".mobileOnly": {
    display: "none",
  },
  // Responsive styles for tablet and mobile
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "30px",
    ".sidebar": {
      width: "100%",
      display: "none", // Hide the regular sidebar on mobile
    },
    ".mobileOnly": {
      display: "block",
    },
    ".mobileCartItems": {
      marginBottom: "20px",
    },
    ".mobileTotalAndButton": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const CheckoutDetailInner = () => {
  const { currentRetailer, isConnected, retailerSyncStatus } =
    useCheckoutRetailer();
  const userProfile = useAppSelector((state) => state.userProfile);
  const [state, send] = useCheckoutUI();
  const { openSections } = state.context;

  const { currentCart } = useFastCheckoutCart();

  useEffect(() => {
    if (currentRetailer) {
      send({ type: "SET_CURRENT_RETAILER", data: currentRetailer });
    }
  }, [currentRetailer, send]);

  useEffect(() => {
    if (userProfile?.userId && state.value === "initial") {
      send({ type: "SET_USER_ID", data: userProfile?.userId });
    }

    if (isConnected && !state.context.isRetailerConnected) {
      send({ type: "SET_RETAILER_CONNECTION", data: isConnected });
    }
  }, [
    isConnected,
    send,
    userProfile?.userId,
    state.context.userId,
    state.context.isRetailerConnected,
    state.value,
  ]);

  // const toggleSection = (step: keyof typeof openSections) => {
  //   send({ type: "EDIT", section: step });
  // };

  const completedPayment = (value: any) => {
    return ["payment_success", "success"].includes(value);
  };

  if (currentCart?.items?.length === 0) {
    return (
      <LandingPageWrapper includeFooter={false}>
        <StyledEmptyBagMessageCard />
      </LandingPageWrapper>
    );
  }

  return (
    <LandingPageWrapper includeFooter={false}>
      <Container>
        {/* Mobile Cart Items - Only shown on mobile */}
        <div className="mobileOnly mobileCartItems">
          <CartItemsContainer>
            <CartTitle>Current Cart</CartTitle>
            {currentCart?.items?.map((item) => (
              <CartProduct
                key={item.stacklineSku}
                product={item}
                disabled={state.value === "payment_success"}
              />
            ))}
          </CartItemsContainer>
        </div>

        {/* Content Section */}
        <div className="content">
          <CollapsibleSection
            isOpen={openSections.signIn || !!userProfile?.userId}
            title={"Account Information"}
            disabled={!openSections.signIn && !userProfile?.userId}
            hideControl={!!userProfile?.userId}
          >
            {/* If user is signed in, show it expanded but no handle and don't let them toggle it */}
            <SignIn />
          </CollapsibleSection>
          <Divider />
          <CollapsibleSection
            isOpen={openSections.retailerLogin}
            disabled={!openSections.retailerLogin}
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <img
                  src={getEntityImage(currentRetailer?.retailerId!, "retailer")}
                  alt="Retailer Logo"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                <div>
                  {`${currentRetailer?.displayName || "Retailer"} Account`}
                </div>
              </div>
            }
            error={!!retailerSyncStatus?.errorMessage}
          >
            <RetailerLoginForm />
          </CollapsibleSection>
          <Divider />
          <CollapsibleSection
            isOpen={openSections.shipping}
            disabled={!openSections.shipping}
            title={"Shipping Address"}
          >
            <ShippingMethodForm />
          </CollapsibleSection>
          <Divider />
          <CollapsibleSection
            isOpen={openSections.payment}
            disabled={!openSections.payment}
            title={completedPayment(state.value) ? "Payment ✅" : "Payment"}
          >
            <CheckoutEditPaymentForm />
          </CollapsibleSection>
        </div>

        {/* Mobile Total and Submit Button - Only shown on mobile */}
        <div className="mobileOnly mobileTotalAndButton">
          <div style={{ width: "100%" }}>
            <CartTotal />
          </div>
          <SolidButton
            disabled={state.value !== "payment_success"}
            onClick={() => send({ type: "NEXT" })}
            style={{
              width: "100%",
              marginTop: "22px",
            }}
          >
            Submit my order
          </SolidButton>
        </div>

        {/* Desktop Sidebar - Hidden on mobile */}
        <div className="sidebar">
          <DebugPanel />
          <CurrentCart />
        </div>
      </Container>
    </LandingPageWrapper>
  );
};

const CheckoutDetail = () => {
  return (
    <FastCheckoutCartProvider>
      <CheckoutUIProvider>
        <CheckoutDetailInner />
      </CheckoutUIProvider>
    </FastCheckoutCartProvider>
  );
};

export default CheckoutDetail;
